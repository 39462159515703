import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { useAuth } from '../../Auth/AuthProvider';
import { searchCarpetParameter } from '../../../js/Constant';
import { getCurrentUserFromJwtToken, getWebsiteId } from '../../../js/Utils.js';
import { Loading } from '../../Common/Loading';
import { Error } from '../../Common/Error';
import { Gallery } from '../Gallery';
import { M3Pax } from "../../Common/M3Pax";
import { ListSearchEngineCV } from '../SearchEngine/compactView/ListSearchEngineCV';
import { ProductCalendar } from '../Common/ProductCalendar';
import { createActivityCarpet } from '../../../js/ProductService';
import { ActivityDetailOption } from './components/ActivityDetailOption';
import { ActivityDetailFavourite } from './components/ActivityDetailFavourite';
import { ActivityDetailHeader } from './components/ActivityDetailHeader';
import { ActivityDetailCategoryInfo } from './components/ActivityDetailCategoryInfo';
import { ActivityDetailDescriptions } from './components/ActivityDetailDescriptions';
import { ActivityDetailDescriptionsAccordion } from './components/ActivityDetailDescriptionsAccordion';
import { ActivityDetailInclusionExclusion } from './components/ActivityDetailInclusionExclusion';
import { ProductNavBar } from '../SearchEngine/ProductNavBar';

export const ActivityDetail = () => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    let params = new URLSearchParams(window.location.search);
    let activityId = params.get('aId');
    let onRequest = params.get('onReq');

    let clientFlowId = params.get('cfId');
    let carpetSearchId = params.get('cId');
    let defaultSelectDate = params.get('sDate');
    let { getQuotationInfoSession } = useAuth();

    // carpet
    // per questioni di ottimizzazione genero il carpet direttamente nella pagina
    const [carpetAvailabilityId, setCarpetAvailabilityId] = useState(null);
    const [carpetStatus, setCarpetStatus] = useState(null);
    const [carpetDataStatus, setCarpetDataStatus] = useState(null);
    const [carpetTime, setCarpetTime] = useState(1);
    const [intervalCarpetPing, setIntervalCarpetPing] = useState([]);

    // page
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [isErrorStaticData, setIsErrorStaticData] = useState(false);
    const [activityData, setActivityData] = useState(null);
    const [activityDetail, setActivityDetail] = useState(null);
    const [showPriceBar, setShowPriceBar] = useState(false);
    const [isLoadingListResult, setIsLoadingListResult] = useState(true);
    const [isErrorListResult, setIsErrorListResult] = useState(false);
    const [quotationInfo, setQuotationInfo] = useState(null);

    // roles
    const [showCost, setShowCost] = useState(false);
    const [enableShowNetPrices, setEnableShowNetPrices] = useState(false);
    const [enableShowPriceBreakDown, setEnableShowPriceBreakDown] = useState(false);
    const [enableCanAddToCart, setEnableCanAddToCart] = useState(false);

    // filtri
    const [totalOptions, setTotalOptions] = useState(0);
    const [onlyAvailable] = useState(true);
    const [isLoadingFilter, setIsLoadingFilter] = useState(false);
    const [filterValues, setFilterValues] = useState(null);
    const [filterSelected, setFilterSelected] = useState({
        numOptionsToView: searchCarpetParameter.activity.detailMaxItem,
        showAllOptions: false,
        onlyAvailable: onlyAvailable,
        selectDate: defaultSelectDate
    });

    useEffect(() => {
        setIsLoading(true);
        setIsError(false);
        getStaticData();

        try {

            let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
            let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);

            if (currentUser.roles) {
                if (!currentUser.roles.includes("FE_NonVisualizzaNetto"))
                    setEnableShowNetPrices(true);

                if (currentUser.roles.includes("FE_ShowCost"))
                    setShowCost(true);

                if (currentUser.roles.includes("FE_ShowPriceBreakDown"))
                    setEnableShowPriceBreakDown(true);
            }

        } catch (ex) {
        }
    }, []);

    useEffect(() => {

        switch (carpetStatus) {
            case 'Processing':
            case 'Queued':
            case 'Completed':
                {
                    break;
                }
            case 'Error':
                {
                    setIsError(true);
                    setIsLoading(false);
                    break;
                }
        }


    }, [carpetStatus]);

    useEffect(() => {
        switch (carpetDataStatus) {
            case 'Completed':
                {
                    callGetNewData();
                    clearCarpetAllInterval();
                    break;
                }

            case 'NewData':
                {
                    callGetNewData();
                    break;
                }

            case 'Processing':
            case 'NoChanges':
                {

                    let interval = setInterval(() => {
                        getCarpetStatus();
                    }, searchCarpetParameter.flight.carpetPingMs);
                    addInterval(interval);

                    break;
                }
        }

    }, [carpetDataStatus]);

    useEffect(() => {

        if (!activityData)
            startDetailSearch();

    }, [activityData]);

    useEffect(() => {
        if (carpetAvailabilityId)
            getCarpetStatus();
    }, [carpetAvailabilityId]);

    useEffect(() => {
        if (getQuotationInfoSession && getQuotationInfoSession.quotationId)
            setQuotationInfo(getQuotationInfoSession);
        else
            setQuotationInfo(null);

    }, [getQuotationInfoSession]);

    const onChangeShowPriceBar = () => {
        setShowPriceBar(!showPriceBar);
    }

    // API carpet
    async function startDetailSearch() {

        let caId = params.get('caId');
        if (!caId) {
            const cookies = new Cookies();
            const sessionToken = cookies.get("SessionToken");

            // chiamo l'api per ottenere i searchParameters
            let requestParam = {
                cultureCode: cultureName,
                carpetId: carpetSearchId
            };

            const requestOption = {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestParam)
            };

            const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}activity/getactivitylistfilters`, requestOption);

            if (fetchedRes.ok) {
                const response = await fetchedRes.json();
                response.activitySearchParameters.activityId = activityId;

                let inputData = {
                    carpetOperation: "AVL",
                    clientFlowId: clientFlowId,
                    sessionToken: sessionToken,
                    activitySearchParameters: response.activitySearchParameters,
                    websiteId: getWebsiteId(),
                    productSubType: 11 // activity
                };

                caId = await createActivityCarpet(inputData);
            }

        }

        setCarpetAvailabilityId(caId);
    }

    async function getCarpetStatus() {
        console.log(`Call GetCarpetStatus [CarpetId = ${carpetAvailabilityId}]`);

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}carpet/getcarpetstatus/${carpetAvailabilityId}`, requestOption);

        if (!fetchedRes.ok) {
            setIsError(true);
        } else {
            const response = await fetchedRes.json();

            let dataStatus = response.dataStatus;
            let status = response.status;
            if (response.success) {
                setCarpetStatus(status);

                if (status !== 'Error') {
                    // messo per richiamare il getCarpetStatus, nel caso riceva piu volte lo status NoChanges.
                    // lo status Processing esiste solo lato FE
                    if (status !== carpetDataStatus)
                        setCarpetDataStatus(dataStatus);
                    else
                        setCarpetDataStatus('Processing');
                }


            } else {
                setIsError(true);
            }

            console.log(`Status ${status}`);
            console.log(`Data Status ${dataStatus}`);
        }

    }

    function addInterval(intervalId) {
        let arr = [...intervalCarpetPing];
        arr.push(intervalId);
        setIntervalCarpetPing(arr);
    }

    function clearCarpetAllInterval() {
        for (let i = 0; i < intervalCarpetPing.length; i++) {
            let intId = intervalCarpetPing[i];
            clearInterval(intId);
        }

        setIntervalCarpetPing([]);
    }

    async function getStaticData() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}activity/GetActivityData/${activityId}/${cultureName}`, requestOption);
        const response = await fetchedRes.json();

        if (response.success) {
            setActivityData(response.activity);
            setIsLoading(false);
        } else {
            setIsErrorStaticData(true);
            console.error(response.errorMsg);
        }
    }

    async function callGetNewData() {
        if (filterValues === null || carpetStatus === 'Completed')
            getActivityDetailFilters();

        getActivityDetail(filterSelected);

        setIsError(false);

        // conto quante chiamate sto facendo
        // ogni chiamata avviene dopo un tot di tempo, quindi in base al numero di chiamate so quanto è passato
        let callTicks = carpetTime * searchCarpetParameter.activity.carpetPingMs;
        let finishTime = callTicks > searchCarpetParameter.activity.carpetMaxTimeMs
        setCarpetTime(carpetTime + 1);

        if (carpetStatus !== 'Completed' && !finishTime) {
            setIsLoadingFilter(true);

            let interval = setInterval(() => {
                getCarpetStatus();
            }, searchCarpetParameter.activity.carpetPingMs);
            addInterval(interval);

        } else {
            setIsLoadingFilter(false);
        }
    }

    async function getActivityDetail(filterSelectedUpdate) {
        setIsErrorListResult(false);
        setIsLoadingFilter(true);

        let requestParam = {
            cultureCode: cultureName,
            carpetId: carpetAvailabilityId,
            clientFlowId: clientFlowId,
            activityId: activityId,

            numOptionsToView: filterSelectedUpdate.numOptionsToView,
            showAllOptions: filterSelectedUpdate.showAllOptions,

            selectDate: filterSelectedUpdate.selectDate,
            onlyAvailable: filterSelectedUpdate.onlyAvailable
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}Activity/getActivityavailability`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setActivityDetail(response.activity);

            let haveResult = response.activity && response.activity.groupedOptions && response.activity.groupedOptions.length > 0;
            if (!haveResult)
                setIsErrorListResult(true);

            setTotalOptions(response.totalOptions);

            if (response.permissions)
                setEnableCanAddToCart(response.permissions['ADDTOCART']);

            if (!filterSelected.selectDate && haveResult) {
                // FABIO setto quello che mi arriva nell'option, la prima volta non ho nessun selectData selezionato
                let filterSelectedUpdate = {
                    ...filterSelected,
                    selectDate: response.activity.groupedOptions[0].optionDate
                };

                setFilterSelected(filterSelectedUpdate);
            }
        } else {
            setIsErrorListResult(true);
        }

        setIsLoadingListResult(false);
        setIsLoadingFilter(false);
    }

    async function getActivityDetailFilters() {
        let requestParam = {
            cultureCode: cultureName,
            carpetId: carpetAvailabilityId
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}activity/getactivityavailabilityfilters`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setFilterValues(response);
        }
    }

    // Filtri
    function onClickShowAll() {
        let filterSelectedUpdate = {
            ...filterSelected,
            showAllOptions: !filterSelected.showAllOptions
        };

        setFilterSelected(filterSelectedUpdate);
        getActivityDetail(filterSelectedUpdate);
    }

    function onSelectDate(myDate) {
        let filterSelectedUpdate = {
            ...filterSelected,
            selectDate: myDate
        };

        setFilterSelected(filterSelectedUpdate);
        getActivityDetail(filterSelectedUpdate);
    }

    async function onClickBook(bookItem) {
        bookItem.cultureCode = cultureName;
        bookItem.carpetId = carpetAvailabilityId;
        bookItem.clientFlowId = clientFlowId;
        bookItem.selectDate = filterSelected.selectDate;
        bookItem.activityId = activityId;

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bookItem)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}activity/addtocart`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response.success) {
                if (quotationInfo && quotationInfo.quotationId) {
                    if (quotationInfo.isFromCart)
                        window.location.href = `cart-quotation`;
                    else
                        window.location.href = `quotationTO/${quotationInfo.quotationId}`;
                } else {
                    window.location.href = `cart`;
                }
            }
        } else {
            console.log(fetchedRes);
        }
    }

    function backToList() {
        window.location.href = `/activity/activitylist/?cId=${carpetSearchId}&cfId=${clientFlowId}`;
    }

    return (
        <>
            {
                configData.Settings.Website.EngineType === 'WITHNAVBAR' && <ProductNavBar moduleSelected="ACTIVITY" />
            }
            {!isLoading && isError && <Error textMsg={t('Error:GenericBlog')} />}
            {
                !isLoading && isErrorStaticData && <div className="card border-light  bg-gray-100 small mb-2">
                    <div className="row small ">
                        <div className="col-12">
                            <Error textMsg={t('')} />
                        </div>
                    </div>
                </div>
            }
            {
                !isLoading && <main className="bg-gray-300">
                    <div className="container mt-5">

                        <div className="row mt-4">
                            <div className="col-12">
                                <div className="cursor-pointer small mb-1" onClick={_ => backToList()}>
                                    <span class="material-icons-outlined icon-small">arrow_back_ios</span> {t('ActionMenu:ReturnList')}
                                </div>
                            </div>
                        </div>
                        <div className="card border-light bg-white p-1">
                            <div className="row px-2">
                                <ActivityDetailFavourite activityDetail={activityData} />
                            </div>
                            <div className="row p-3">
                                 <Gallery gallery={activityData.images} alternativeText={activityData.name} productType={configData.Settings.Products.Activity.IdTipoProdotto} />
                            </div>
                            <div className="row px-1">
                                <div className="col-12 col-sm-8 col-md-8 col-lg-8">
                                    <ActivityDetailHeader activityDetail={activityData} />
                                </div>
                                <div className="col-12 col-sm-4 col-md-4 col-lg-4 text-right">
                                    <div>
                                        <var></var>
                                    </div>
                                    <button className="btn btn-sm btn-neutral p-1 m-0 d-none"><data m3lab="General.LookMap">{t('General:LookMap')}</data></button>
                                </div>
                                <div className="col-12 col-sm-8 col-md-8 col-lg-8">
                                    <ActivityDetailDescriptions
                                        activityDetail={activityData}
                                        showOnlyGeneral="true" />
                                </div>
                                <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <ActivityDetailCategoryInfo
                                        activityId={activityId}
                                        activityInfo={activityDetail}
                                        activityDetail={activityData} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-8 col-md-8 col-lg-8">
                                    {
                                        filterValues && <ProductCalendar productCalendar={filterValues.calendar}
                                            defaultDate={filterSelected.selectDate}
                                            onSelectDate={onSelectDate}
                                            imageUrl="https://media.activitiesbank.com/38027/ENG/XL/38027_1.jpg" />
                                    }

                                    <h5 className="border-bottom p-0 mt-4 mb-0">
                                        <data m3lab="Product.Activity.Options">
                                            {t("Product:Activity:Options")}
                                        </data>
                                        {
                                            isLoadingFilter && !isLoadingListResult && <div className="spinner-border spinner-border-sm text-primary ml-2" role="status"></div>
                                        }
                                    </h5>
                                    <label></label>

                                    {
                                        isError && <div className="card border-light bg-gray-100 small mb-1 py-3 px-2">
                                            <div className="row small">
                                                <div className="col-12 text-center text-danger py-2">
                                                    <Error textMsg={t('Product:Activity:ErrorDetail')} />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        !isError && <div className="card border-light bg-gray-300">
                                            <div className="row p-1 ">
                                                <div className="h5 col-12 col-lg-5 ">
                                                    {t("Print:YourComposition")}
                                                </div>
                                                <div className="h5 col-12 col-lg-2 ">
                                                    {
                                                        filterValues && <M3Pax adt={filterValues.activitySearchParameters.passengerComposition.adults}
                                                            chd={filterValues.activitySearchParameters.passengerComposition.children}
                                                            inf="0" />
                                                    }
                                                </div>
                                                <div className="col-12 col-lg-5 text-right">
                                                    {
                                                        enableShowNetPrices && <button className={(showPriceBar ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow"} m3rool="f_ShowPriceBar" onClick={(e) => { onChangeShowPriceBar() }}>
                                                            <data m3lab="Button.Net">
                                                                {t("Button:Net")}
                                                            </data>
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                            {
                                                isLoadingListResult && !isError && !isErrorListResult && <div className="card border-light  bg-gray-100 small mb-2">
                                                    <div className="row small ">
                                                        <div className="col-12">
                                                            <Loading textMsg={t("MyBook:Loading")} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (!isLoadingListResult && (isErrorListResult || !activityDetail.groupedOptions || activityDetail.groupedOptions.length === 0)) && <div className="card border-light  bg-gray-100 small mb-2">
                                                    <div className="row small">
                                                        <div className="col-12">
                                                            <Error textMsg={t("Print:NothingOption")} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                !isLoadingListResult && !isErrorListResult && activityDetail.groupedOptions && activityDetail.groupedOptions.map((option, key) => {
                                                    return <ActivityDetailOption key={key}
                                                        option={option}
                                                        showPriceBar={showPriceBar}
                                                        showCost={showCost}
                                                        enableShowPriceBreakDown={enableShowPriceBreakDown}
                                                        enableCanAddToCart={enableCanAddToCart}
                                                        onBook={onClickBook}
                                                        quotationInfo={quotationInfo} />
                                                })
                                            }
                                        </div>
                                    }

                                    {
                                        !isLoadingListResult && !isErrorListResult && totalOptions > searchCarpetParameter.activity.detailMaxItem && <div className="row p-1">
                                            <div className="col-12 text-center">
                                                <button className="btn btn-link bg-white border-0 mt-2" onClick={(e) => onClickShowAll()}>
                                                    {!filterSelected.showAllOptions && <> {t("Product:Activity:ShowAll").replace('#TOTALOPTION#', totalOptions)}</>}
                                                    {filterSelected.showAllOptions && <> {t("Product:Activity:ShowLess").replace('#TOTALOPTION#', searchCarpetParameter.activity.detailMaxItem)} </>}
                                                </button>
                                            </div>
                                        </div>
                                    }

                                    <div id="activityDetailDescriptionsAccordion" className="mt-5">
                                        <ActivityDetailDescriptionsAccordion activityDetail={activityData} gallery={activityData.images} />
                                    </div>
                                </div>
                                <div className="col-4">
                                    {
                                        filterValues && <ListSearchEngineCV
                                            imageUrl="https://cdn.travelplace.ch/travelplace40/img/maps_list.jpg"
                                            searchParameters={filterValues.activitySearchParameters}
                                            productType={configData.Settings.Products.Activity.IdTipoProdotto}
                                            typeSearch="AVL"
                                            extraParams={{
                                                activityId: activityId,
                                                carpetId: carpetAvailabilityId,
                                                clientFlowId: clientFlowId,
                                                testRequest: params.get('testRequest'),
                                                onRequest: onRequest,
                                                city: (activityData && activityData.city ? activityData.city : ''),
                                                productName: (activityData && activityData.name ? activityData.name : ''),
                                                latitude: (activityData && activityData.latitude ? activityData.latitude : ''),
                                                longitude: (activityData && activityData.longitude ? activityData.longitude : '')
                                            }}
                                            isShowMap={true}
                                        />
                                    }
                                    {
                                        !filterValues && <ListSearchEngineCV
                                            imageUrl="https://cdn.travelplace.ch/travelplace40/img/maps_list.jpg"
                                            productType={configData.Settings.Products.Activity.IdTipoProdotto}
                                            typeSearch="AVL"
                                            extraParams={{
                                                activityId: activityId,
                                                carpetId: carpetAvailabilityId,
                                                clientFlowId: clientFlowId,
                                                testRequest: params.get('testRequest'),
                                                onRequest: onRequest,
                                                city: (activityData && activityData.city ? activityData.city : ''),
                                                productName: (activityData && activityData.name ? activityData.name : ''),
                                                latitude: (activityData && activityData.latitude ? activityData.latitude : ''),
                                                longitude: (activityData && activityData.longitude ? activityData.longitude : '')
                                            }}
                                            isShowMap={true}
                                        />
                                    }

                                    <ActivityDetailInclusionExclusion activityDetail={activityData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            }
        </>
    );
}