/* Template Utilities */
import configData from "../../appsettings.json";
import { addDaysToDatePicker } from "../../js/Utils";

export const TemplateInclusion = {
    Optional: 0,
    Mandatory: 1,
    AtLeastOne: 2
}

export const getSearchParameterDate = (searchParameters, templateData, productType, dateType) => {
    switch (productType)
    {
        case configData.Settings.Products.Activity.IdTipoProdotto:
        case configData.Settings.Products.Structure.IdTipoProdotto:
        case configData.Settings.Products.Tour.IdTipoProdotto:
            {
                if (templateData.daysRange.length === 1) {
                    // case when there isn't a configured range in dates of product template
                    let minDays = templateData.daysRange[0] - 1;
                    let date = addDaysToDatePicker(searchParameters.checkIn, minDays);
                    return date;
                }
                else {
                    if (dateType === "CHECKIN") {
                        let minDays = templateData.daysRange[0] - 1;
                        let checkIn = addDaysToDatePicker(searchParameters.checkIn, minDays);
                        return checkIn;
                    } else {
                        let maxDays = templateData.daysRange[templateData.daysRange.length - 1] - 1;
                        let checkOut = addDaysToDatePicker(searchParameters.checkIn, maxDays);
                        return checkOut;
                    }
                }
            }

        case configData.Settings.Products.Flight.IdTipoProdotto:
        case configData.Settings.Products.CarRental.IdTipoProdotto:
        case configData.Settings.Products.Cruise.IdTipoProdotto:
        case configData.Settings.Products.Transfer.IdTipoProdotto:
            {
                let checkIn = new Date(searchParameters.checkIn);
                return checkIn;
            }

        default:
            return null;
    }
}

export const getPassengerComposition = (searchParameters, productType) => {
    switch (productType) {
        // structure + flight (passo anche i flight perche la logica la fa internamente l'API)
        case configData.Settings.Products.Structure.IdTipoProdotto:
        case configData.Settings.Products.Tour.IdTipoProdotto:
        case configData.Settings.Products.Flight.IdTipoProdotto:
            return searchParameters.roomsComposition;

        // car rental
        case configData.Settings.Products.CarRental.IdTipoProdotto:
            {
                let passengerComposition = { adults: 1, children: 0, childAges: [] };
                let outArray = [];
                outArray.push(passengerComposition);
                return outArray;
            }

        case configData.Settings.Products.Cruise.IdTipoProdotto:
        case configData.Settings.Products.Transfer.IdTipoProdotto:
            {
                let passengerComposition = { adults: 0, children: 0, childAges: [] };

                for (let i = 0; i < searchParameters.roomsComposition.length; i++) {
                    passengerComposition.adults += searchParameters.roomsComposition[i].adults;
                    passengerComposition.children += searchParameters.roomsComposition[i].children;

                    if (searchParameters.roomsComposition[i].childAges && searchParameters.roomsComposition[i].childAges.length > 0) {
                        for (let j = 0; j < searchParameters.roomsComposition[i].childAges.length; j++) {
                            passengerComposition.childAges.push(searchParameters.roomsComposition[i].childAges[j]);
                        }
                    }
                }
                if (passengerComposition.childAges.length === 0) passengerComposition.childAges = null;
                else passengerComposition.childAges = passengerComposition.childAges.sort().reverse(); // order by desc

                let outArray = [];
                outArray.push(passengerComposition);
                return outArray;
            }

        // other products
        default:
            {
                let passengerComposition = { adults: 0, children: 0, childAges: [] };

                for (let i = 0; i < searchParameters.roomsComposition.length; i++) {
                    passengerComposition.adults += searchParameters.roomsComposition[i].adults;
                    passengerComposition.children += searchParameters.roomsComposition[i].children;

                    if (searchParameters.roomsComposition[i].childAges && searchParameters.roomsComposition[i].childAges.length > 0) {
                        for (let j = 0; j < searchParameters.roomsComposition[i].childAges.length; j++) {
                            passengerComposition.childAges.push(searchParameters.roomsComposition[i].childAges[j]);
                        }
                    }
                }
                if (passengerComposition.childAges.length === 0) passengerComposition.childAges = null;
                else passengerComposition.childAges = passengerComposition.childAges.sort().reverse(); // order by desc

                return passengerComposition;
            }
    }
}

export async function getCarpetStatus(carpetId) {
    const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
    const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}carpet/getcarpetstatus/${carpetId}`, requestOption);
    const response = await fetchedRes.json();
    return response;
}


export const getTitleClass = (inclusionType) => {
    switch (inclusionType) {
        case 0:
            return "text-danger";

        case 1:
            return "text-green-900";

        default:
            return "";
    }
}

export const getTitleLabel = (inclusionType, title, t) => {
    if (title)
        return title;

    switch (inclusionType) {
        case 0:
            return t(`Template:Exclusion`);

        case 1:
            return t(`Template:Inclusion`);

        default:
            return "";
    }
}

// update properties of item identified by "templateDataId"
export const updateInfoSelectedProduct = (
    selectedProducts,
    templateDataId,
    setSelected, selected,
    setProductSelection, productSelection,
    setProductPriceDetail, productPriceDetail,
    setCarpetId, carpetId,
    setSrcCarpetId, srcCarpetId,
    setHasFinished, hasFinished,
    setIsError, isError
) => {
    const updSelectedProducts = selectedProducts.map((sp, idx) => {
        if (sp.TemplateDataId === templateDataId) {
            if (setSelected) sp.Selected = selected;
            if (setProductSelection) sp.ProductSelection = productSelection;
            if (setProductPriceDetail) sp.ProductPriceDetail = productPriceDetail;
            if (setCarpetId) sp.CarpetId = carpetId;
            if (setSrcCarpetId) sp.SrcCarpetId = srcCarpetId;
            if (setHasFinished) sp.HasFinished = hasFinished;
            if (setIsError) sp.IsError = isError;
        }
        return sp;
    });

    return updSelectedProducts;
}

export const updateTemplateDataLinked = (template, selectedTemplateDataId, isSelected, selectedProducts) => {
    let templateDatas = [];
    for (let i = 0; i < template.steps.length; i++) {
        for (let j = 0; j < template.steps[i].templateData.length; j++) {
            templateDatas.push(template.steps[i].templateData[j]);
        }
    }

    // se non ho prodotti collegati, esco
    let selectedTemplateData = templateDatas.filter(x => x.templateDataId === selectedTemplateDataId)[0];
    if (!selectedTemplateData.templatesDataLinked || selectedTemplateData.templatesDataLinked.length === 0)
        return null;

    // altrimenti metto selected/unselected ogni prodotto collegato
    let updSelectedProducts = [...selectedProducts];
    for (let i = 0; i < selectedTemplateData.templatesDataLinked.length; i++) {
        let linkedTemplateDataId = selectedTemplateData.templatesDataLinked[i];

        // se lo stato è già impostato correttamente, esco
        if (updSelectedProducts.filter(x => x.TemplateDataId === linkedTemplateDataId)[0].Selected === isSelected)
            continue;

        // altrimenti aggiorno il prodotto in questione
        updSelectedProducts = updateInfoSelectedProduct(updSelectedProducts, linkedTemplateDataId, true, isSelected, false, null, false, null, false, null, false, null);

        // se il tipo di Inclusion è "AtLeastOne", rendo coerenti gli altri prodotti dello stesso tipo in questo step:
        // questo significa che, se lo sto selezionando, devo deselezionare tutti gli altri, altrimenti non faccio niente perchè le selezioni sono gestite ricorsivamente
        for (let j = 0; j < template.steps.length; j++) {
            let linkedTemplateDatas = template.steps[j].templateData.filter(x => x.templateDataId === linkedTemplateDataId);
            if (linkedTemplateDatas && linkedTemplateDatas.length > 0 && linkedTemplateDatas[0].inclusion === TemplateInclusion.AtLeastOne) {

                let actualStep = template.steps[j];
                let actualTemplateData = linkedTemplateDatas[0];

                let otherAtLeastOneTemplateDatas = actualStep.templateData.filter(x =>
                    x.templateDataId !== linkedTemplateDataId &&
                    x.productType === actualTemplateData.productType &&
                    x.inclusion === TemplateInclusion.AtLeastOne
                );

                if (!otherAtLeastOneTemplateDatas || otherAtLeastOneTemplateDatas.length === 0)
                    continue;

                if (isSelected) {
                    // sto selezionando il prodotto in questione, quindi metto tutti gli altri unselected
                    for (let k = 0; k < otherAtLeastOneTemplateDatas.length; k++) {
                        updSelectedProducts = updateInfoSelectedProduct(updSelectedProducts, otherAtLeastOneTemplateDatas[k].templateDataId, true, false, false, null, false, null, false, null, false, null);
                    }
                }
            }
        }

        // chiamata ricorsiva => propago la selezione su eventuali altri prodotto collegati in cascata
        // (chiama se stessa passandogli come selected/unselected il linkedTemplateDataId)
        let recursiveUpdatedSelectedProducts = updateTemplateDataLinked(template, linkedTemplateDataId, isSelected, selectedProducts);
        if (recursiveUpdatedSelectedProducts) updSelectedProducts = recursiveUpdatedSelectedProducts;
    }

    return updSelectedProducts;
}

// tells if a product can start search or must wait for other products
export const canStartSearch = (templateDataId, stepProducts, selectedProducts) => {
    /*
     * templateDataId = product that ask to start
     * stepProducts = alternatives (if any)
     * selectedProducts = status of the products (Selected, HasFinished, IsError, etc.)
    */

    if (!stepProducts || stepProducts.length === 0)
        return true;

    let actualProduct = selectedProducts.filter(x => x.TemplateDataId === templateDataId)[0];
    if (actualProduct.Selected)
        return true;

    let selectedProduct = selectedProducts.filter(x => x.Selected)[0];
    if (!selectedProduct.HasFinished)
        return false;

    return true;
}
