import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GalleryList } from "../../Common/Image/GalleryList";
import { M3Icon } from "../../../Common/M3Icon";
import { ProductPrice } from "../../Common/Pricing/ProductPrice";
import configData from "../../../../appsettings.json";

export const TransferItemList = ({ item, showPriceBar, showCost, extraParam, modalOpenProductId, enableCanAddToCart, quotationInfo, templateInfo, onClickSearch, removeTrippie, updateTrippie }) => {
    const { t } = useTranslation();
    const [buttonBookLabel, setButtonBookLabel] = useState('Button:AddToCart');
    const [buttonBookEnabled, setButtonBookEnabled] = useState(true);

    useEffect(() => {
        if (!templateInfo && !quotationInfo)
            setButtonBookLabel(`Button:AddToCart`);

        if (templateInfo)
            setButtonBookLabel(`Button:Select`);

        if (quotationInfo && !quotationInfo.quotationItemIdToChange)
            setButtonBookLabel(`Button:AddQuotation`);

        if (quotationInfo && quotationInfo.quotationItemIdToChange)
            setButtonBookLabel(`Button:ReplaceQuotation`);

    }, [quotationInfo, templateInfo])

    useEffect(() => {
        if (templateInfo || quotationInfo || (enableCanAddToCart && enableCanAddToCart.allowed))
            setButtonBookEnabled(true);
        else
            setButtonBookEnabled(false);

    }, [enableCanAddToCart])

    useEffect(() => {
        if (!extraParam) {
            extraParam = {};
        }

        extraParam.productId = item.internalId;
    }, [item]);

    function onModalOpenProductId(productId) {
        modalOpenProductId(productId);
    }

    return (
        <>
            <div className="card shadow shadow-hover mb-4 animate-up-3 ">
                <div className="row no-gutters">
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <GalleryList
                            productId={item.internalId}
                            defaultImage={item.imageURL}
                            alternativeText={item.name}
                            onClickSearch={onClickSearch}
                            productType={configData.Settings.Products.Transfer.IdTipoProdotto}
                        />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <div className="card-body pt-2 px-0">
                            <div>
                                <div class="w-100">
                                    {/* Type */}
                                    <div class="w-100">
                                        <label>
                                            <var m3lab="Product.Transfer.IsPrivate">{item.isPrivate ? t('Product:Transfer:Private') : t('Product:Transfer:Shared')}</var>
                                        </label>
                                    </div>

                                    {/* Name */}
                                    <div class="h5">
                                        <var m3lab="Product.Transfer.Name">
                                            {item.name}
                                        </var>
                                    </div>

                                    {/* OneWay/RoundTrip */}
                                    <div class="w-100">
                                        <label>
                                            {item.isRoundTrip
                                                ? <M3Icon iconName="RoundTrip" externalClass="icon-15" />
                                                : <M3Icon iconName="OneWay" externalClass="icon-15" />
                                            }
                                            <var m3lab="Product.Transfer.IsRoundTrip">
                                                {item.isRoundTrip
                                                    ? t('Product:Transfer:RoundTrip')
                                                    : t('Product:Transfer:OneWay')
                                                }
                                            </var>
                                        </label>
                                    </div>

                                    {/* Pickup / Dropoff */}
                                    <div class="w-100">
                                        <label>
                                            <M3Icon iconName="Place" externalClass="icon-15" />
                                            <var><data m3lab="Product.Transfer.PickupDestinationName">{t('Product:Transfer:Pickup')}</data>: {item.pickupDestinationName}</var>
                                        </label>
                                    </div>
                                    <div class="w-100">
                                        <label>
                                            <M3Icon iconName="Place" externalClass="icon-15" />
                                            <var><data m3lab="Product.Transfer.DropoffDestinationName">{t('Product:Transfer:Dropoff')}</data>: {item.dropoffDestinationName}</var>
                                        </label>
                                    </div>

                                    {/* Inclusions */}
                                    <div class="d-flex mt-5">
                                        {/* Passengers */}
                                        <div class="w-30">
                                            <label title={t('Product:Transfer:Passengers')}>
                                                <M3Icon iconName="PaxAdl" externalClass="icon-15" />
                                                <var m3lab="Product.Transfer.MinCapacity|Product.Transfer.MaxCapacity">Min. {item.minCapacity} - Max. {item.maxCapacity}</var>
                                            </label>
                                        </div>

                                        {/* Baggages */}
                                        <div class="w-25">
                                            <label title={t('Product:Transfer:Baggages')}>
                                                <M3Icon iconName="FlightBagage" externalClass="icon-15" />
                                                <var m3lab="Product.Transfer.NumberOfBags">
                                                    {!item.numberOfBags || item.numberOfBags === "0"
                                                        ? `${t('Product:Transfer:Baggages')}: ${item.numberOfBags ?? '--'}`
                                                        : `${t('Product:Transfer:Baggages')}: ${item.numberOfBags}`
                                                    }
                                                </var>
                                            </label>
                                        </div>

                                        {/* Stops */}
                                        <div class="w-25">
                                            <label title={t('Product:Transfer:Stops')}>
                                                <M3Icon iconName="Tour" externalClass="icon-15" />
                                                <var m3lab="Product.Transfer.Stops">
                                                    {!item.stops || item.stops === "0"
                                                        ? `${t('Product:Transfer:Stops')}: ${item.stops ?? '--'}`
                                                        : `${t('Product:Transfer:Stops')}: ${item.stops}`
                                                    }
                                                </var>
                                            </label>
                                        </div>

                                        {/* Duration */}
                                        <div class="w-25">
                                            <label title={t('Product:Transfer:Duration')}>
                                                <M3Icon iconName="Clock" externalClass="icon-15" />
                                                <span><var m3lab="Product.Transfer.Duration">{item.duration}</var></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 p-1 pr-3">
                        <ProductPrice
                            productId={item.internalId}
                            productType={configData.Settings.Products.Transfer.IdTipoProdotto}
                            startDate={item.startDate}
                            endDate={item.endDate}
                            pricing={item.pricing}
                            supplier={item.supplier}
                            showPriceBar={showPriceBar}
                            showCost={showCost}
                            otherParam={{ buttonLabel: t(buttonBookLabel), buttonEnabled: buttonBookEnabled }}
                            typePrice="TotPrice"
                            isTrippie={extraParam && extraParam.isTrippie}
                            showPriceTrippie={extraParam && extraParam.showPriceTrippie}
                            removeTrippie={removeTrippie}
                            updateTrippie={updateTrippie}
                            onClickSearch={onClickSearch}
                            templateInfo={templateInfo}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
