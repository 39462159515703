import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../../Common/M3Icon";

export const TransferSummary = ({ transfer, isAlternative }) => {
    const { t } = useTranslation();
    const [fontSize, setFontSize] = useState('');

    useEffect(() => {
        if (isAlternative)
            setFontSize('h075');
    }, [transfer]);

    return (
        <>
            <span m3ico="Transfer mr-2 icon-15">
                <M3Icon iconName="Transfer" externalClass="mr-2 icon-15 text-custom" hasLabel={false} />
            </span>

            {transfer.pickupDestinationName} > {transfer.dropoffDestinationName} {transfer.isRoundTrip && <>(A/R)</>}
        </>
    );
}
