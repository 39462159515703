import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { Loading } from '../Common/Loading';
import { M3Pax } from "../Common/M3Pax";
import { TemplateGallery } from './components/TemplateGallery';
import { TemplateSummary } from './components/TemplateSummary';
import { TemplateBoxStatic } from './components/TemplateBoxStatic';
import { TemplateInclusions } from './components/TemplateInclusions';
import { TemplateSteps } from './components/TemplateSteps';
import { TemplateSearchEngine } from '../Product/SearchEngine/TemplateSearchEngine';
import { TemplateInclusion, updateInfoSelectedProduct, updateTemplateDataLinked } from './TemplateUtils';
import { CartAddictionalProducts } from '../Cart/CartAddictionalProducts';
import { getCurrentUserFromJwtToken, formatPrice } from "../../js/Utils";
import configData from "../../appsettings.json";
import { FormRichiestaInterventoBooking } from './../Cart/Form/FormRichiestaInterventoBooking';
import { TemplateHeaderImage } from './components/TemplateHeaderImage';
import { useAuth } from '../Auth/AuthProvider';

export const Template = () => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    let { paramTemplate } = useParams(); // può essere o TemplateId o TemplateCode

    const [template, setTemplate] = useState({});
    const [templateCommMap, setTemplateCommMap] = useState(null);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // quotation
    const [quotationInfo, setQuotationInfo] = useState(null);
    const [quotationMode, setQuotationMode] = useState(false);
    let { getQuotationInfoSession } = useAuth();

    // user authorizations
    const [userLockBook, setUserLockBook] = useState(false);
    const [isQuotationMode, setIsQuotationMode] = useState(false);
    const [enableShowNetPrices, setEnableShowNetPrices] = useState(false);
    const [showNetPrices, setShowNetPrices] = useState(false);

    const [searchParameters, setSearchParameters] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [allProductsFinished, setAllProductsFinished] = useState(false);

    const [addictionalProducts, setAddictionalProducts] = useState(null);
    const [addictionalProductsOriginal, setAddictionalProductsOriginal] = useState(null);
    const [requestAddictionalProducts, setRequestAddictionalProducts] = useState(null);
    const [forceRenderAddProds, setForceRenderAddProds] = useState(1);

    const [addToCartError, setAddToCartError] = useState(false);
    const [saveTemplateQuotationError, setTemplateQuotationError] = useState(false);
    const [isStartAddToCart, setIsStartAddToCart] = useState(false);
    const [isStartSaveTemplateQuotation, setIsStartSaveTemplateQuotation] = useState(false);

    const [richiestaBookOnSendRes, setRichiestaBookOnSendRes] = useState(null);

    // debug
    const [searchParams, setSearchParams] = useSearchParams();
    let debug = searchParams.get("debug");


    useEffect(() => {
        let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
        let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);
        if (currentUser.roles) {
            //if (!currentUser.roles.includes("FE_NonVisualizzaNetto")) setEnableShowNetPrices(true);
            if (currentUser.roles.includes("FE_NoBook")) setUserLockBook(true);
        }

        let inputData = { cultureCode: cultureName };

        if (paramTemplate && Number.isInteger(parseInt(paramTemplate))) {
            inputData["templateId"] = paramTemplate;
        }
        else if (paramTemplate) {
            inputData["templateCode"] = paramTemplate;
        }

        setIsLoading(true);
        callGetTemplate(inputData);
    }, []);

    useEffect(() => {
        if (getQuotationInfoSession && getQuotationInfoSession.quotationId) {
            setQuotationInfo(getQuotationInfoSession);
            setQuotationMode(true);
        }
        else {
            setQuotationInfo(null);
            setQuotationMode(false);
        }

    }, [getQuotationInfoSession]);

    useEffect(() => {
        // check to init requestAddictionalProducts
        if (template && searchParameters) {
            let rq = { TemplateId: template.templateId, PaxCompositions: searchParameters.roomsComposition, SelectedProducts: selectedProducts };
            if (!requestAddictionalProducts || JSON.stringify(requestAddictionalProducts) !== JSON.stringify(rq)) {
                setRequestAddictionalProducts(rq);
            }
        }

        // check for allProductsFinished
        if (!selectedProducts || selectedProducts.length === 0 ||
            selectedProducts.filter(x => x.Selected && x.HasFinished).length !== selectedProducts.filter(x => x.Selected).length) {
            setAllProductsFinished(false);
            return;
        }

        let updateForceVal = forceRenderAddProds + 1;
        setForceRenderAddProds(updateForceVal);

        setAllProductsFinished(true);
    }, [template, searchParameters, selectedProducts]);


    async function callGetTemplate(inputData) {
        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Template/GetTemplate', requestOption);
        const response = await fetchedRes.json();

        if (response && response.success) {
            setTemplate(response.template);
            setTemplateCommMap(response.commissionableMap);

            // set default selected products
            let tmpSelectedProducts = [];
            if (response.template.steps && response.template.steps.length > 0) {
                for (let i = 0; i < response.template.steps.length; i++) {
                    if (response.template.steps[i].templateData && response.template.steps[i].templateData.length > 0) {
                        for (let j = 0; j < response.template.steps[i].templateData.length; j++) {
                            let isDefaultSelected = response.template.steps[i].templateData[j].inclusion === TemplateInclusion.Mandatory ||
                                (response.template.steps[i].templateData[j].inclusion === TemplateInclusion.AtLeastOne && response.template.steps[i].templateData[j].isDefault === true);
                            tmpSelectedProducts.push({
                                TemplateDataId: response.template.steps[i].templateData[j].templateDataId,
                                ProductType: response.template.steps[i].templateData[j].productType,
                                ProductSubType: response.template.steps[i].templateData[j].productSubType,
                                ProductSelection: null,
                                ProductPriceDetail: { DisplayPrice: 0, NetPrice: 0, Markup: 0, Fee: 0, Commission: 0 },
                                CarpetId: null,
                                SrcCarpetId: null,
                                Selected: isDefaultSelected,
                                HasFinished: false,
                                IsError: false,
                                Inclusion: response.template.steps[i].templateData[j].inclusion,
                                Step: response.template.steps[i].step,
                                StepName: response.template.steps[i].webDestinationName
                            });
                        }
                    }
                }
            }

            tmpSelectedProducts = tmpSelectedProducts.sort((a, b) => (a.TemplateDataId > b.TemplateDataId) ? 1 : ((b.TemplateDataId > a.TemplateDataId) ? -1 : 0))
            setSelectedProducts(tmpSelectedProducts);

            setIsLoading(false);
        }
        else {
            setIsError(true);
            console.error(response.errorMsg);
        }
    }

    function onSetSearchParameters(searchParameters) {
        if (searchParameters.SelectedFlightTDataId) {
            setSelectedFlight(searchParameters);
        }

        setSearchParameters(searchParameters);
        let divItinerary = document.getElementById("Itinerary");
        divItinerary.style.display = "block";
    }

    function setSelectedFlight(searchParameters) {
        let templateDatas = [];
        for (let i = 0; i < template.steps.length; i++) {
            for (let j = 0; j < template.steps[i].templateData.length; j++) {
                templateDatas.push(template.steps[i].templateData[j]);
            }
        }

        let flights = templateDatas.filter(x => x.step === 1 && x.productType === configData.Settings.Products.Flight.IdTipoProdotto);
        for (let i = 0; i < flights.length; i++) {
            let currTemplateDataFlight = flights[i];

            let selectedProdFlight = selectedProducts.filter(x => x.TemplateDataId === currTemplateDataFlight.templateDataId)[0];
            let toBeSelected = selectedProdFlight.TemplateDataId === searchParameters.SelectedFlightTDataId;
            if (toBeSelected) {
                // select
                let updSelectedProducts = updateInfoSelectedProduct(selectedProducts, selectedProdFlight.TemplateDataId, true, true, false, null, false, null, false, null, false, null, false, null, false, null);
                setSelectedProducts(updSelectedProducts);
            } else {
                // unselect
                let updSelectedProducts = updateInfoSelectedProduct(selectedProducts, selectedProdFlight.TemplateDataId, true, false, false, null, false, null, false, null, false, null, false, null, false, null);
                setSelectedProducts(updSelectedProducts);
            }

            let updTemplateDataLinked = updateTemplateDataLinked(template, currTemplateDataFlight.templateDataId, toBeSelected, selectedProducts);
            if (updTemplateDataLinked) setSelectedProducts(updTemplateDataLinked);
        }
    }

    // pricing
    const getTotalPrice = () => {
        if (!selectedProducts || selectedProducts.length === 0)
            return 0;

        let totalPrice = 0;

        for (let i = 0; i < selectedProducts.length; i++) {
            if (selectedProducts[i].Selected && selectedProducts[i].ProductPriceDetail) {
                totalPrice += selectedProducts[i].ProductPriceDetail.DisplayPrice;
            }
        }

        if (addictionalProducts && addictionalProducts.length > 0) {
            for (let i = 0; i < addictionalProducts.length; i++) {
                if (addictionalProducts[i].selected && !isNaN(addictionalProducts[i].price)) {
                    totalPrice += addictionalProducts[i].price;
                }
            }
        }

        let sPrice = formatPrice(totalPrice, template.customerCurrency, cultureName);

        return sPrice;
    }
    const getNetPrices = () => {
        if (!selectedProducts || selectedProducts.length === 0)
            return (<></>);

        let totalNetPrice = 0;
        let totalMarkup = 0;
        let totalFee = 0;
        let totalCommission = 0;

        for (let i = 0; i < selectedProducts.length; i++) {
            if (selectedProducts[i].Selected && selectedProducts[i].ProductPriceDetail) {
                totalNetPrice += selectedProducts[i].ProductPriceDetail.NetPrice;
                totalMarkup += selectedProducts[i].ProductPriceDetail.Markup;
                totalFee += selectedProducts[i].ProductPriceDetail.Fee;
                totalCommission += selectedProducts[i].ProductPriceDetail.Commission;
            }
        }

        if (addictionalProducts && addictionalProducts.length > 0) {
            for (let i = 0; i < addictionalProducts.length; i++) {
                if (addictionalProducts[i].selected && !isNaN(addictionalProducts[i].netPrice)) {
                    totalNetPrice += addictionalProducts[i].netPrice;
                }
            }
        }

        let sNetPrice = formatPrice(totalNetPrice, template.customerCurrency, cultureName);
        let sMarkup = formatPrice(totalMarkup, template.customerCurrency, cultureName);
        let sFee = formatPrice(totalFee, template.customerCurrency, cultureName);
        let sCommission = formatPrice(totalCommission, template.customerCurrency, cultureName);

        return (
            <React.Fragment>
                Netto: <span>{sNetPrice}</span><br />
                Markup: <span>{sMarkup}</span><br />
                Fee: <span>{sFee}</span>
                {templateCommMap && Object.values(templateCommMap)[0] && (<><br />Commissione <span>{sCommission}</span></>)}
            </React.Fragment>
        );
    }
    const handleShowNetPrices = (e) => {
        e.preventDefault();
        setShowNetPrices(!showNetPrices);
    }

    // addictional products
    const handleAddProdSelection = (data) => {
        let newData = !addictionalProducts ? [] : [...addictionalProducts];
        for (let i = 0; i < data.length; i++) {
            let curProd = data[i];
            if (newData.filter(x => x.idProdotto === curProd.idProdotto).length === 0) {
                // add
                newData.push(curProd);
            } else {
                // update
                const updNewData = newData.map((d, idx) => {
                    if (d.idProdotto === curProd.idProdotto) {
                        d.selected = curProd.selected;
                        d.price = curProd.price;
                        d.netPrice = curProd.netPrice;
                    }
                    return d;
                });
                newData = updNewData;
            }
        }

        setAddictionalProducts(newData);
    }

    const handleSaveOriginalAddProd = (data) => {
        setAddictionalProductsOriginal(data);
    }

    // cart
    const isAllValidProducts = () => {
        let totalProducts = selectedProducts.filter(x => x.Selected && x.HasFinished).length;
        let validProducts = selectedProducts.filter(x => x.Selected && x.HasFinished && x.ProductSelection).length;

        return validProducts === totalProducts;
    }
    const showAddToCart = () => {
        if (!allProductsFinished)
            return false;

        let allValidProducts = isAllValidProducts();
        return allValidProducts;
    }
    async function addToCart(event) {
        if (event) event.preventDefault();

        setIsStartAddToCart(true);

        var inputData = {
            TemplateId: template.templateId,
            TemplateUserId: template.userId,
            CultureCode: cultureName,
            SelectedProducts: selectedProducts,
            AddictionalProductSelected: addictionalProducts
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Template/AddToCart', requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response.success) {
                window.location.href = `${configData.Settings.Base_Url}cart`;
            } else {
                setAddToCartError(true);
            }
        } else {
            setAddToCartError(true);
        }

        setIsStartAddToCart(false);
    }

    async function addToQuotation(event) {
        if (event) event.preventDefault();

        setIsStartAddToCart(true);

        var inputData = {
            TemplateId: template.templateId,
            TemplateUserId: template.userId,
            CultureCode: cultureName,
            SelectedProducts: selectedProducts,
            AddictionalProductSelected: addictionalProducts
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Template/AddToQuotation', requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response.success) {
                window.location.href = `${configData.Settings.Base_Url}quotationTO/${quotationInfo.quotationId}`;
            } else {
                setAddToCartError(true);
            }
        } else {
            setAddToCartError(true);
        }

        setIsStartAddToCart(false);
    }

    // Funzionalita richiesta intervento booking
    function onSendRichiestaInterventoBooking(inputData) {
        let myObj = {
            richiestaInterventoBooking: inputData,
            addictionalProductSelected: addictionalProducts,
            templateId: template.templateId,
            templateUserId: template.userId,
            cultureCode: cultureName,
            selectedProducts: selectedProducts,
            addictionalProducts: addictionalProductsOriginal
        }

        saveRichiestaInterventoBooking(myObj);
    }

    async function saveRichiestaInterventoBooking(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'template/SaveRichiestaInterventoBooking', requestOption);
        const response = await fetchedRes.json();

        if (response) {
            setRichiestaBookOnSendRes(response);
        } else {
            setRichiestaBookOnSendRes({ success: false, message: "Generic Error" });
        }
    }

    // open popup "RichiestaInterventoBooking"
    const openModalRichiestaInterventoBooking = useCallback(() => {
        let myBtn = document.getElementById("open_RichiestaInterventoBooking");
        myBtn.click();
    }, []);

    // Funzionalita save template
    const onSaveTemplateQuotation = () => {
        saveTemplateQuotation();
    }

    async function saveTemplateQuotation() {
        setIsStartSaveTemplateQuotation(true);

        var inputData = {
            TemplateId: template.templateId,
            TemplateUserId: template.userId,
            CultureCode: cultureName,
            SelectedProducts: selectedProducts,
            AddictionalProductSelected: addictionalProducts,
            addictionalProducts: addictionalProductsOriginal
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Template/SaveTemplateQuotation', requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response.success) {
                let quotatioDocUrl = `${configData.Settings.Base_Url}Documents/quotation/` + response.quotationId;
                if (response.authCode)
                    quotatioDocUrl += `?ac=${response.authCode}`;

                window.location.href = quotatioDocUrl;
            } else {
                setTemplateQuotationError(true);
            }
        } else {
            setTemplateQuotationError(true);
        }
    }


    return (
        <>
            {isLoading &&
                <main>
                    <div className="container mt-6">
                        <div className="row">
                            <div className="col-12">
                                <Loading textMsg={t('MyBook:Loading')} />
                            </div>
                        </div>
                    </div>
                </main>
            }
            {!isLoading &&
                <main>
                    <div>
                        <TemplateHeaderImage photoGallery={template.photoGallery} template={template} />
                    </div>

                    {/*Body container*/}
                    <div className="container">
                        <div className="row">
                            {/*left column*/}
                            {/*title and description*/}
                            <div className="col-8 p-3">
                                <h3>{template.name}</h3>
                                <div className="text-justify" dangerouslySetInnerHTML={{ __html: template.description }}></div>
                            </div>
                            <div className="col-8">
                                {debug &&
                                    <div style={{ border: '1px solid red', padding: '10px', wordWrap: 'break-word' }}>
                                        <b>CommissionableMap:</b> <br />{JSON.stringify(templateCommMap)} <br /><br />
                                        <b>Search Parameters:</b> <br />{JSON.stringify(searchParameters)} <br /><br />
                                        <b>Selected TemplateDataId:</b> <br />{JSON.stringify(selectedProducts)} <br /><br />
                                        <b>Products Finished:</b> {selectedProducts && selectedProducts.filter(x => x.Selected && x.HasFinished).length}/{selectedProducts && selectedProducts.filter(x => x.Selected).length} <br />
                                        <b>Products Errors:</b> {selectedProducts && selectedProducts.filter(x => x.IsError && x.HasFinished).length}/{selectedProducts && selectedProducts.length} <br />
                                        <b>ReadyToCart:</b> {showAddToCart() ? "YES" : "NO"} <br />
                                    </div>
                                }

                                {/*itinerary steps*/}
                                <div className="collapse" id="Itinerary">
                                    {template && searchParameters && (
                                        <div className="card card-body">
                                            <div className="row">

                                                <div className="col-12">
                                                    {/*Total Composition*/}
                                                    <div className="d-inline">
                                                        <div className="d-inline-block">
                                                            <div className="h3 mr-3">Itinerario di viaggio</div>
                                                            <div className="border border-1 border-bottom border-std w-25"></div>
                                                        </div>
                                                        <div className="d-inline-block">
                                                            <M3Pax adt={searchParameters.totalAdults} chd={searchParameters.totalChildren} inf={searchParameters.totalInfants} />
                                                        </div>
                                                    </div>

                                                    {/*Template Steps*/}
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 no-gutters">
                                                            <TemplateSteps template={template} steps={template.steps} searchParameters={searchParameters} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} />
                                                        </div>
                                                    </div>

                                                    {/*Addictional Products*/}
                                                    {allProductsFinished && requestAddictionalProducts && forceRenderAddProds &&
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                            <div className="card shadow mt-3 p-2">
                                                                {<CartAddictionalProducts
                                                                    handleAddProdSelection={handleAddProdSelection}
                                                                    handleSaveOriginalAddProd={handleSaveOriginalAddProd}
                                                                    templateAddProdRequest={requestAddictionalProducts}
                                                                    forceRender={forceRenderAddProds}
                                                                />}
                                                            </div>
                                                        </div>
                                                    }

                                                    {/*Total Price*/}
                                                    {allProductsFinished && showAddToCart() && addictionalProducts &&
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                            <div className="card shadow mt-3 p-2 text-right">
                                                                <div className="h5">
                                                                    Prezzo totale <span>{getTotalPrice()}</span>
                                                                    {enableShowNetPrices &&
                                                                        <button className="tp-btn-select p-1 px-2 pt-2 ml-2 rounded border-0 shadow" onClick={(e) => { handleShowNetPrices(e) }}>
                                                                            <data m3lab="Button.Net">{t("Button:Net")}</data>
                                                                        </button>
                                                                    }
                                                                </div>
                                                                {showNetPrices &&
                                                                    <div className="h6">
                                                                        {getNetPrices()}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    }

                                                    {/*Add To Cart and  Request booking*/}
                                                    {showAddToCart() &&
                                                        <React.Fragment>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right p-3">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right p-3">
                                                                        <button className="btn tp-btn-book w-100" onClick={e => onSaveTemplateQuotation()}>
                                                                            {isStartSaveTemplateQuotation
                                                                                ? <div className="spinner-border spinner-border-sm text-primary" role="status"><span className="sr-only"></span></div>
                                                                                : <>{t('Button:SaveTemplateQuotation')}</>
                                                                            }
                                                                        </button>
                                                                    </div>
                                                                    {!userLockBook && !quotationMode &&
                                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right p-3">
                                                                            <button className="btn tp-btn-book w-100" onClick={e => addToCart(e)}>
                                                                                {isStartAddToCart
                                                                                    ? <div className="spinner-border spinner-border-sm text-primary" role="status"><span className="sr-only"></span></div>
                                                                                    : <>
                                                                                        <span dangerouslySetInnerHTML={{ __html: t(`Button:AddToCart`) }}></span>
                                                                                    </>
                                                                                }
                                                                            </button>
                                                                        </div>
                                                                    }
                                                                    {quotationMode &&
                                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right p-3">
                                                                            <button className="btn tp-btn-book w-100" onClick={e => addToQuotation(e)}>
                                                                                {isStartAddToCart
                                                                                    ? <div className="spinner-border spinner-border-sm text-primary" role="status"><span className="sr-only"></span></div>
                                                                                    : <>
                                                                                        <span dangerouslySetInnerHTML={{ __html: t(`Button:AddQuotation`) }}></span>
                                                                                    </>
                                                                                }
                                                                            </button>
                                                                        </div>
                                                                    }
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right p-3">
                                                                        <button className="btn tp-btn-book w-100" onClick={e => openModalRichiestaInterventoBooking()}>
                                                                            {t('Button:RequestBooking')}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {addToCartError &&
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right p-3">
                                                                    <h6 className="text-danger">{t(`Template:AddToCartError`)}</h6>
                                                                </div>}
                                                            {saveTemplateQuotationError &&
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right p-3">
                                                                    <h6 className="text-danger">{t(`Template:SaveTemplateQuotation`)}</h6>
                                                                </div>}
                                                        </React.Fragment>
                                                    }

                                                    {/*All products not avail*/}
                                                    {(allProductsFinished && !isAllValidProducts()) &&
                                                        <React.Fragment>
                                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right p-3">
                                                                <button className="btn tp-btn-book w-100" onClick={e => openModalRichiestaInterventoBooking()}>
                                                                    {t('Button:RequestBooking')}
                                                                </button>
                                                            </div>

                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center p-3">
                                                                <h6 className="text-danger"><div dangerouslySetInnerHTML={{ __html: t(`Template:AllProductsNotAvail`) }}></div></h6>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/*box static data*/}
                                {template.steps && template.steps.length > 0 && <TemplateBoxStatic steps={template.steps} />}
                            </div>

                            {/*right column*/}
                            <div className="col-4 mt-2">
                                <div className="row">
                                    {/*inclusions*/}
                                    {template.inclusions && template.inclusions.length > 0 && <TemplateInclusions inclusions={template.inclusions} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            }

            {/*Search Modal*/}
            <div className="modal tp-search-input fade" id="EngineModifyTemplate" tabIndex="-1" aria-labelledby="EngineModifyTemplate" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen-xxl-down">
                    <div className="modal-content">
                        <div className="modal-header p-0">
                            <div className="h4 text-center w-100 mt-2">
                                {t('Button:Search')}
                            </div>
                            <button type="button" className="btn-close mt-2 pr-4" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body bg-gray-300">
                            {template && <TemplateSearchEngine searchParameters={searchParameters} onSetSearchParameters={onSetSearchParameters} modeView="MODAL" template={template} />}
                        </div>
                    </div>
                </div>
            </div>

            {
                <FormRichiestaInterventoBooking
                    onSend={onSendRichiestaInterventoBooking}
                    onSendResponse={richiestaBookOnSendRes}
                    minDate={""}
                    maxDate={""}
                />
            }
        </>
    );
}