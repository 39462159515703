import React, { useEffect, useState } from 'react';
import configData from "../../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { GalleryList } from "../../Common/Image/GalleryList";
import { M3Icon } from "../../../Common/M3Icon";
import { ProductActionMenu } from "../../Common/ProductActionMenu";
import { ProductPriceBlock } from '../../Common/Pricing/ProductPriceBlock';
import { M3StructureStars } from '../../../Common/M3StructureStars';
import { getIconFavoutire } from '../../../../js/Utils.js';

export const HotelItemListBlock = ({ item, showPriceBar, showCost, extraParam, modalOpenProductId, onClickSearch }) => {
    const { t } = useTranslation();
    const [isFavourite, setIsFavourite] = useState(false);

    useEffect(() => {
        if (!extraParam)
            extraParam = {};

        extraParam.productId = item.structureId;

        setIsFavourite(item.favourite);
    }, [item]);


    function onModalOpenProductId(productId) {
        modalOpenProductId(productId);
    }

    function onClickBlockSearch() {
        onClickSearch(item.structureId);
    }

    // block the click that go to detail
    function stopPropagationClickMenu(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    function onClickFavourite() {
    }

    return (
        <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4  p-1 m-0 cursor-pointer" onClick={_ => onClickBlockSearch()}>
            <div className="card shadow shadow-hover my-4 animate-up-3 h-100">
                <div className="m-0 p-0">
                    <div className="row no-gutters">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <GalleryList
                                productId={item.structureId}
                                defaultImage={item.thumbUrl}
                                gallery={item.images}
                                alternativeText={item.name}
                            />
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="card p-1">
                                <div>
                                    <div className="row">
                                        <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                            <var>
                                                <M3StructureStars category={item.category} />
                                            </var>
                                            {item.isSuggestion && <data m3ico="Star text-facebook">
                                                <var className="material-icons text-facebook">
                                                    stars
                                                </var>
                                            </data>}
                                        </div>
                                        <div className="col-1 col-sm-1 col-md-1 col-lg-2 col-xl-2">
                                            <div className="btn-group text-right float-end pr-2">
                                                <button className="btn p-0 m-0" type="button" id="menuItem1" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false" onClick={(e) => stopPropagationClickMenu(e)}>
                                                    <data m3ico="List">
                                                        <M3Icon iconName="MenuFunctions" />
                                                    </data>
                                                </button>
                                                <ProductActionMenu
                                                    productId={item.structureId}
                                                    productType={configData.Settings.Products.Structure.IdTipoProdotto}
                                                    extraParam={extraParam}
                                                    handleModalOpenProductId={onModalOpenProductId}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="h5">
                                        <var>{item.name}</var>
                                        {getIconFavoutire(isFavourite, t, onClickFavourite, false)}
                                    </div>

                                    <div className="w-100">
                                        <label>
                                            <data m3ico="Place icon-10">
                                                <M3Icon iconName="Place" externalClass="icon-10" />
                                            </data>
                                            <span className="ml-1 lh-sm">
                                                <var>{item.address}</var>
                                            </span>
                                            <span className="ml-1 lh-sm">
                                                {item.distance &&
                                                    <var>({(item.distance / 1000).toString().replace(".", ",")} Km)</var>
                                                }
                                            </span>
                                        </label>
                                    </div>
                                    <div className="w-100">
                                        {
                                            item.bestRoomNames && item.bestRoomNames.map((bestRoomName, indexbestRoomName) => {
                                                return <div key={indexbestRoomName}>
                                                    <label>
                                                        <data m3ico="Night">
                                                            <M3Icon iconName="Night" externalClass="icon-15" />
                                                        </data>
                                                        <span className="ml-1 lh-sm">
                                                            <var>{bestRoomName}</var>
                                                        </span>
                                                    </label>
                                                </div>
                                            })
                                        }
                                    </div>

                                    <div className="w-100  mt-3">
                                        <label>
                                            <data m3lab="Product.Structure.Filter.NumenrSolutions">{t(`Product:Structure:Filter:NumenrSolutions`)} </data>
                                            <var>{item.totalRooms}</var>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-1 pr-3">
                            <ProductPriceBlock
                                pricing={item.pricing}
                                productType={configData.Settings.Products.Structure.IdTipoProdotto}
                                supplier={item.supplier}
                                showPriceBar={showPriceBar}
                                isPackage={item.isPackage}
                                isPromotion={item.isPromotion}
                                showCost={showCost}
                                typePrice="NTPax"
                                otherParam={{ mealPlanId: item.bestMealPlanId, buttonEnabled: true }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}