import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import configData from "../../../appsettings.json";
import { getDateObj } from "../../../js/Utils.js";

export const M3MonthPicker = ({ selectMonths, extraOptions, validDateRule, onSelectDate, productType }) => {
    const { t } = useTranslation();

    const [dates, setDates] = useState(null);
    const [minDate, setMinDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date(2099, 12, 31));
    const [locale] = useState('it');

    // settaggi calendario
    const [selectRange, setSelectRange] = useState('multiple');

    useEffect(() => {
        if (!selectMonths)
            setDates(null);

    }, [selectMonths]);

    useEffect(() => {
        if (!extraOptions || !extraOptions.multiDate) {
            setSelectRange('single');
        }
        else {
            setSelectRange('multiple');
        }

        if (extraOptions && extraOptions.validDates && extraOptions.validDates.length > 0) {
            let aValidDates = [];
            for (let i = 0; i < extraOptions.validDates.length; i++) {
                aValidDates.push(new Date(extraOptions.validDates[i]));
            }

            aValidDates = aValidDates.sort((a, b) => a - b);

            let minDate = aValidDates[0];
            if (minDate < new Date())
                minDate = new Date();

            setDates(minDate);
            setMinDate(minDate);
            setMaxDate(aValidDates[extraOptions.validDates.length - 1]);
        } else if (validDateRule && validDateRule.minDate) {
            let minDateRule = getDateObj(validDateRule.minDate);
            setMinDate(minDateRule);
        } else {
            setMinDate(new Date());
        }

    }, [extraOptions, validDateRule]);

    addLocale('it', {
        firstDayOfWeek: 1,
        dayNames: [t("Calendar:Day:Sunday"), t("Calendar:Day:Monday"), t("Calendar:Day:Tuesday"), t("Calendar:Day:Wedsnday"), t("Calendar:Day:Thursday"), t("Calendar:Day:Friday"), t("Calendar:Day:Saturday")],
        dayNamesShort: [t("Calendar:DayShort:Sunday"), t("Calendar:DayShort:Monday"), t("Calendar:DayShort:Tuesday"), t("Calendar:DayShort:Wedsnday"), t("Calendar:DayShort:Thursday"), t("Calendar:DayShort:Friday"), t("Calendar:DayShort:Saturday")],
        dayNamesMin: [t("Calendar:Day:Sunday")[0], t("Calendar:Day:Monday")[0], t("Calendar:Day:Tuesday")[0], t("Calendar:Day:Wedsnday")[0], t("Calendar:Day:Thursday")[0], t("Calendar:Day:Friday")[0], t("Calendar:Day:Saturday")[0]],
        monthNames: [t("Calendar:Month:January"), t("Calendar:Month:February"), t("Calendar:Month:March"), t("Calendar:Month:April"), t("Calendar:Month:May"), t("Calendar:Month:June"), t("Calendar:Month:July"), t("Calendar:Month:August"), t("Calendar:Month:September"), t("Calendar:Month:October"), t("Calendar:Month:November"), t("Calendar:Month:December")],
        monthNamesShort: [t("Calendar:MonthShort:January"), t("Calendar:MonthShort:February"), t("Calendar:MonthShort:March"), t("Calendar:MonthShort:April"), t("Calendar:MonthShort:May"), t("Calendar:MonthShort:June"), t("Calendar:MonthShort:July"), t("Calendar:MonthShort:August"), t("Calendar:MonthShort:September"), t("Calendar:MonthShort:October"), t("Calendar:MonthShort:November"), t("Calendar:MonthShort:December")],
        today: t("Calendar:Today"),
        clear: t("Calendar:Clear")
    });

    function updateDates(values) {
        if (selectRange === 'multiple') {
            updateMultipleMonths(values);
        } else {
            updateDatesRange(values)
        }
    }

    function updateMultipleMonths(values) {
        setDates(values);
    }

    function updateDatesRange(values) {
        setDates(values);
        onSelectDate(values);
    }

    function getWidth() {
        try {
            const calendarInput = document.getElementById("CalendarInput");
            const grandparentElement = calendarInput.parentElement.parentElement;

            // Usando offsetWidth per ottenere la larghezza dell'elemento
            const width = grandparentElement.offsetWidth;
            if (width)
                return width;
        } catch (ex) {

        }

        return "100%";
    }


    return (
        <>
            <Calendar
                value={dates}
                onChange={(e) => updateDates(e.value)}
                minDate={minDate}
                maxDate={maxDate}
                view="month"
                style={{ width: getWidth() }}
                inline
                selectionMode={selectRange}
                locale={locale}
                readOnlyInput />
        </>
    );
}
