import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { InputSearchDestination } from './InputSearchDestination';
import { InputSearchDestinationOnlyRead } from './InputSearchDestinationOnlyRead';
import { Loading } from '../Loading';
import { M3Icon } from "../M3Icon";

export const InputSearchDestinationWithModalContainer = ({ externalId, defaultSelectDestination, isLoading, productType, destinations, validDestinations, openOnClick, onSelectSearchDestination, lastSearchItems, modeView, closeExternal, clearSearch, extraOptions, onOpenPanelDestination }) => {
    const { t } = useTranslation();

    const [selectDestination, setSelectDestination] = useState({ Text: '', items: [] });
    const [showDestination, setShowDestination] = useState(false);

    const [idModal] = useState(getRandomInt());
    function getRandomInt() {
        return Math.floor(Math.random() * 100000);
    }
    const modalId = "Destinations_" + idModal;

    useEffect(() => {
        let modalIdName = "Destinations_" + idModal;
        let modalDestination = document.getElementById(modalIdName);
        if (modalDestination) {
            modalDestination.addEventListener('shown.bs.modal', function () {
                onOpenPanelDestination();
            });
        }

    }, [])

    useEffect(() => {
        if (defaultSelectDestination) {
            setSelectDestination(defaultSelectDestination);
        }
        else
            setSelectDestination({ Text: '', items: [] });

    }, [defaultSelectDestination]);

    useEffect(() => {
        setShowDestination(closeExternal);
    }, [closeExternal]);

    function callOnSelectDestination() {
        onSelectSearchDestination(selectDestination);
        closePanelDestination();
    }

    function openPanelDestination() {
        if (!externalId)
            onOpenPanelDestination();
        else
            onOpenPanelDestination(externalId);

        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnModalDestination_" + idModal);
            btn.click();
        }

        setShowDestination(true);
    }

    function closePanelDestination() {
        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnCloseModalDestination_" + idModal);
            btn.click();
        }
        
        setShowDestination(false);
    }

    function clearSelection() {
        setShowDestination(false);
    }

    function onChangeCheckbox(event, item) {
        let updSelectDestination = { ...selectDestination };
        const isSelected = event.target.checked;
        if (isSelected) {
            if (!updSelectDestination.items)
                updSelectDestination.items = [];

            updSelectDestination.items.push(item);
            if (updSelectDestination.Text === "")
                updSelectDestination.Text = item.description;
            else 
                updSelectDestination.Text = updSelectDestination.Text + ", " + item.description;
        }
        else {
            updSelectDestination.Text = updSelectDestination.Text.replace(item.description + ", ", "").replace(", " +item.description, "");
            updSelectDestination.items = updSelectDestination.items.filter(wId => wId.webDestinationId !== item.webDestinationId);
        }

        setSelectDestination(updSelectDestination);
        onSelectSearchDestination(updSelectDestination);
    }

    function isValidDestination(webDestinationId) {
        if (!validDestinations || validDestinations.length === 0)
            return true;

        let isValid = validDestinations.filter(x => x.webDestinationId === webDestinationId).length > 0;
        return isValid;
    }

    return (
        <>
            <div className="w-100">
                {
                    (modeView !== 'MODAL' || !showDestination) && <InputSearchDestinationOnlyRead
                        defaultSelectDestination={selectDestination}
                        openPanelDestination={openPanelDestination}
                        productType={productType}
                        extraOptions={extraOptions}
                        clearSelection={clearSelection}
                    />
                }
            </div>
            <a id={"btnModalDestination_" + idModal} data-bs-toggle="modal" data-bs-target={"#" + modalId}></a>
            <div className='d-block'>
                <div
                    className={"modal " + (modeView === 'MODAL' && showDestination ? "tp-search-input position-relative d-block" : "")}
                    id={modalId}
                    tabIndex="-1"
                    aria-labelledby={"DestinationsLabel_" + modalId}
                    aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header p-0">
                                {
                                    modeView !== 'MODAL' &&
                                    <div className="py-2 pl-4 h5 mt-2">
                                        <M3Icon iconName="Destination" externalClass="mr-2 h090 position-relative top-3" />
                                        {t('SearchEngine:Destination')}
                                    </div>
                                }
                                {
                                    modeView === 'MODAL' && showDestination && <InputSearchDestinationOnlyRead
                                        defaultSelectDestination={selectDestination}
                                        openPanelDestination={openPanelDestination}
                                        productType={productType}
                                        extraOptions={{ clearSelection: true}}
                                        clearSelection={clearSelection}
                                    />
                                }
                            </div>
                            <div className="modal-body">
                                {isLoading && <Loading />}
                                {
                                    !isLoading && <div className="mt-3">
                                        {
                                            destinations && destinations.length > 0 && destinations.map((parent, index) => {
                                                return <div className="row mt-2" key={index} >
                                                    <div className="col-12">
                                                        <M3Icon iconName="World" externalClass="mr-2 h090 position-relative top-3 text-white bg-custom p-1 rounded" />
                                                        <span className="text-black fw-bold h5">{parent.description}</span>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="row mx-0">
                                                            {
                                                                parent.children && parent.children.map((chd, indexCHD) => {
                                                                    return <div key={indexCHD} className="col-4 mt-2">
                                                                        <input id={"CB_Destination_" + chd.webDestinationId}
                                                                            type="checkbox"
                                                                            className="mr-1"
                                                                            value={selectDestination.items && selectDestination.items.some(x => x.webDestinationId === chd.webDestinationId)}
                                                                            onChange={(e) => onChangeCheckbox(e, chd)}
                                                                            defaultChecked={selectDestination.items && selectDestination.items.some(x => x.webDestinationId === chd.webDestinationId)}
                                                                            disabled={isValidDestination(chd.webDestinationId) ? "" : "disabled"}
                                                                        />
                                                                        <label htmlFor={"CB_Destination_" + chd.webDestinationId} className="h6">{chd.description}</label>
                                                                        {isValidDestination(chd.webDestinationId) ? <></> : <small className="ml-1" style={{ 'fontSize': '10px' }}>(Coming Soon)</small>}
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                }
                            </div>
                            <a id={"btnCloseModalDestination_" + idModal} data-bs-dismiss="modal"></a>
                            {
                               <div className="modal-footer">
                                    <button className="btn bt-sm tp-btn-delete" onClick={_ => closePanelDestination()}>{t('Button:Close')}</button>
                                    <button className="btn bt-sm tp-btn-confirm" onClick={_ => callOnSelectDestination()}>{t('Button:Confirm')}</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
