import React, { useEffect, useState } from 'react';
import configData from "../../../../appsettings.json";
import { ProductPriceBlock } from "../../Common/Pricing/ProductPriceBlock";
import { FlightLegsListBlock } from "./FlightLegsListBlock";
import { getBasicDetailUrl } from '../../../../js/Utils.js';


export const FlightItemListBlock = ({ item, showPriceBar, showCost, extraParam, setModalOpenProductId, onClickSearch }) => {
    const [detailLink, setDetailLink] = useState('');

    useEffect(() => {
        if (!extraParam)
            extraParam = {};

        extraParam.productId = item.uniqueId;
        setDetailLink(getBasicDetailUrl(configData.Settings.Products.Flight.IdTipoProdotto, extraParam));
    }, [item]);

    function handleClickSearch() {
        onClickSearch({ itineraryId: item.uniqueId, fareId: item.fares && item.fares[0] && item.fares[0].uniqueId });
    }

    return (
        <>
            <div className="col-12 col-sm-10 col-md-10 col-lg-4 col-xl-4 p-2 cursor-pointer" onClick={(e) => handleClickSearch()}>
                <div className="card shadow shadow-hover mb-4 animate-up-3 p-2">
                    <div className="card-body pt-2 px-0 pb-0">
                        <div>
                            <div className="w-100">
                                {item && item.legs && item.legs.length > 0 && item.legs.map((product, key) => {
                                    return <FlightLegsListBlock key={key}
                                        item={product}
                                        index={key}
                                        fares={item.fares}
                                        setModalOpenProductId={setModalOpenProductId}
                                    />
                                })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pr-0 border-top border-gray-300">

                        <ProductPriceBlock
                            productType={configData.Settings.Products.Flight.IdTipoProdotto}
                            pricing={item.fares && item.fares[0] ? item.fares[0].price : null}
                            supplier={item.fares && item.fares[0] ? item.fares[0].supplier : null}
                            isPackage={item.fares[0].hasRestriction}
                            showCost={showCost}
                            showPriceBar={showPriceBar}
                            detailLink={detailLink}
                            typePrice="TotPrice"
                            otherParam={{ mealPlanId: item.bestMealPlanId, buttonEnabled: true }}
                            setModalOpenProductId={setModalOpenProductId}
                        />

                        {item.fares[0].hasRestriction && <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-2 h075">Package TO</div>}
                    </div>
                </div>
            </div>
        </>
    );
}