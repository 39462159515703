import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { useAuth } from '../../Auth/AuthProvider';
import { getCurrentUserFromJwtToken, getWebsiteId } from '../../../js/Utils.js';
import { ProductHeader } from '../Common/ProductHeader';
import { Loading } from '../../Common/Loading';
import { M3Icon } from "../../Common/M3Icon";
import { Error } from '../../Common/Error';
import { createFlightCarpetAvailability, getFlightFare, getFlightFareDetails, getFlightFareRules, getFlightAncillaries, getAncillarySelectedMapping, getTotalAncillarySelectedMapping, ancillariesCompare } from '../../../js/ProductService';
import { FlightChooseFare } from './components/FlightChooseFare';
import { FlightItemList } from './components/FlightItemList';
import { FlightAncillaryType } from './components/FlightAncillaryType';
import { FlightFareRules } from './components/FlightFareRules';
import { FlightPriceFareDetail } from './components/FlightPriceFareDetail';
import { FlightDetailResume } from './components/FlightDetailResume';
import { ProductNavBar } from '../SearchEngine/ProductNavBar';

export const FlightDetail = () => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    let params = new URLSearchParams(window.location.search);
    let carpetId = params.get('cId');
    let clientFlowId = params.get('cfId');
    let itineraryId = params.get('itineraryId');

    let { getQuotationInfoSession } = useAuth();

    const [fareId, setFareId] = useState(null);
    // carpet
    const [flightFare, setFlightFare] = useState(null);
    const [flightFareDetail, setFlightFareDetail] = useState(null);
    const [flightFareDetailSelectedPrice, setFlightFareDetailSelectedPrice] = useState(null);
    const [flightFareRules, setFlightFareRules] = useState(null);
    const [flightAncillaries, setFlightAncillaries] = useState(null);
    const [avlCarpetId, setAvlCarpetId] = useState(null);

    // page
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [isFareError, setIsFareError] = useState(false);
    const [isLoadingFares, setIsLoadingFares] = useState(false);
    const [fareSelected, setFareSelected] = useState(null);
    const [quotationInfo, setQuotationInfo] = useState(null);

    const [ancillaryTypeBagagge] = useState(['BAGGAGE', 'FLG-BAG']);
    const [ancillariesSelectedBagagge, setAncillariesSelectedBagagge] = useState([]);
    const [ancillariesSelectedBagaggeTotalPrice, setAncillariesSelectedBagaggeTotalPrice] = useState({ amount: 0, currency: 'EUR' });
    const [ancillariesSelectedBase, setAncillariesSelectedBase] = useState([]);
    const [ancillariesSelectedBaseTotalPrice, setAncillariesSelectedBaseTotalPrice] = useState({ amount: 0, currency: 'EUR' });

    // roles
    const [showCost, setShowCost] = useState(false);
    const [enableShowNetPrices, setEnableShowNetPrices] = useState(false);
    const [enableCanAddToCart, setEnableCanAddToCart] = useState(false);
    const [excludePackageTO, setExcludePackageTO] = useState(false);

    const onClickFare = (uniqueId) => {
        getFareDetail(uniqueId, avlCarpetId);
        getFareRules(uniqueId, avlCarpetId);
        getAncillaries(uniqueId, avlCarpetId);

        setFareId(uniqueId);

        setAncillariesSelectedBagagge([]);
        setAncillariesSelectedBagaggeTotalPrice({ amount: 0, currency: 'EUR' });
        setAncillariesSelectedBase([]);
        setAncillariesSelectedBaseTotalPrice({ amount: 0, currency: 'EUR' });
    }

    function onChangeAncillary(ancillary, valueSelected, index, paxIndex) {
        let updBagagge = [...ancillariesSelectedBagagge];
        let updBase = [...ancillariesSelectedBase];

        if (ancillaryTypeBagagge.includes(ancillary.category.toUpperCase())) {
            updBagagge = getAncillarySelectedMapping(updBagagge, ancillary, index, valueSelected, paxIndex);
        } else {
            updBase = getAncillarySelectedMapping(updBase, ancillary, index, valueSelected, paxIndex);
        }

        let totalPriceBase = 0.0;
        let totalPriceBagagge = 0.0;
        let currency = 'EUR';

        for (let i = 0; i < updBagagge.length; i++) {
            totalPriceBagagge += getTotalAncillarySelectedMapping(updBagagge[i]);
        }

        for (let i = 0; i < updBase.length; i++) {
            totalPriceBase += getTotalAncillarySelectedMapping(updBase[i]);
        }

        setAncillariesSelectedBagaggeTotalPrice({
            amount: totalPriceBagagge,
            currency: currency
        });
        setAncillariesSelectedBagagge(updBagagge.sort(ancillariesCompare));

        setAncillariesSelectedBaseTotalPrice({
            amount: totalPriceBase,
            currency: currency
        });
        setAncillariesSelectedBase(updBase.sort(ancillariesCompare));
    }


    useEffect(() => {
        setIsLoading(true);
        setIsError(false);

        setFareId(params.get('fareId'));

        getCreateCarpet(params.get('fareId'));

        try {

            let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
            let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);

            if (currentUser.roles) {
                if (!currentUser.roles.includes("FE_NonVisualizzaNetto"))
                    setEnableShowNetPrices(true);

                if (currentUser.roles.includes("FE_ShowCost"))
                    setShowCost(true);

                if (currentUser.roles.includes("W_ExcludePackageTO"))
                    setExcludePackageTO(true);
            }

        } catch (ex) {
        }
    }, []);

    useEffect(() => {
        if (getQuotationInfoSession && getQuotationInfoSession.quotationId)
            setQuotationInfo(getQuotationInfoSession);
        else
            setQuotationInfo(null);

    }, [getQuotationInfoSession]);

    // API carpet
    async function getCreateCarpet(fareId) {
        var inputData = {
            "carpetOperation": "AVL",
            "clientFlowId": clientFlowId,
            "sessionToken": cookies.get("SessionToken"),
            "websiteId": getWebsiteId(),
            "flightStandardSearchParameters": null,
            "productSubType": 51,
            "cultureCode": cultureName,
            "srcCarpetId": carpetId,
            "selectedItineraryUniqueId": itineraryId ? parseInt(itineraryId) : "",
        };
        const response = await createFlightCarpetAvailability(inputData);

        if (response.success && response.status !== "Error") {
            setAvlCarpetId(response.carpetId);

            var data = {
                "cultureCode": cultureName,
                "srcCarpetId": carpetId,
                "avlCarpetId": response.carpetId,
                "selectedItineraryUniqueId": itineraryId ? parseInt(itineraryId) : null,
                "selectedFareUniqueId": fareId ? parseInt(fareId) : null,
                "excludeRestriction": excludePackageTO
            };

            await getFare(data);

        } else {
            setIsError(true);
        }
    }

    async function getFare(inputData) {
        const responseFare = await getFlightFare(inputData);

        if (responseFare.success) {
            if (!responseFare.flight.fares || !responseFare.flight.fares[0]) {
                setIsError(true);
                return;
            }
            setFlightFare(responseFare.flight);
            setFareSelected(responseFare.flight.fares && responseFare.flight.fares.length > 0 && responseFare.flight.fares[0].uniqueId);
            setIsLoading(false);

            if (responseFare.permissions)
                setEnableCanAddToCart(responseFare.permissions['ADDTOCART']);

            await getFareDetail(responseFare.flight.fares[0].uniqueId, inputData.avlCarpetId);
            await getFareRules(responseFare.flight.fares[0].uniqueId, inputData.avlCarpetId);
            await getAncillaries(responseFare.flight.fares[0].uniqueId, inputData.avlCarpetId);
        } else {
            setIsError(true);
        };
    }

    async function getFareDetail(uniqueId, _avlCarpetId = null) {

        setIsLoadingFares(true);

        var inputData = {
            "cultureCode": cultureName,
            "srcCarpetId": carpetId,
            "avlCarpetId": _avlCarpetId,
            "selectedItineraryUniqueId": itineraryId ? parseInt(itineraryId) : null,
            "selectedFareUniqueId": parseInt(uniqueId),
        };
        const responseFareDetail = await getFlightFareDetails(inputData);
        if (responseFareDetail.success) {
            setFareSelected(uniqueId);
            setFlightFareDetail(responseFareDetail.flight);
            setFlightFareDetailSelectedPrice(responseFareDetail.flight.fares && responseFareDetail.flight.fares[0] && responseFareDetail.flight.fares[0].price && responseFareDetail.flight.fares[0].price.displayPrice)
        } else {
            setIsFareError(true);
        };

        setIsLoadingFares(false);
    }

    async function getFareRules(uniqueId, _avlCarpetId = null) {
        var inputData = {
            "cultureCode": cultureName,
            "avlCarpetId": _avlCarpetId,
            "selectedItineraryUniqueId": itineraryId ? parseInt(itineraryId) : null,
            "selectedFareUniqueId": parseInt(uniqueId),
        };
        const responseFareDetail = await getFlightFareRules(inputData);

        if (responseFareDetail.success) {
            setFlightFareRules(responseFareDetail.fareRules);
        } else {
            setIsFareError(true);
        };
    }

    async function getAncillaries(uniqueId, _avlCarpetId = null) {
        // Lufthansa e Travelfusion
        var inputData = {
            cultureCode: cultureName,
            avlCarpetId: _avlCarpetId,
            selectedItineraryUniqueId: itineraryId ? parseInt(itineraryId) : null,
            selectedFareUniqueId: parseInt(uniqueId),
        };

        const responseFareDetail = await getFlightAncillaries(inputData);

        if (responseFareDetail.success) {

            if (responseFareDetail.hasAncillaries) {
                let bagaggeAncillariesHasAncillaries = false;
                let baseAncillariesHasAncillaries = false;

                let mapping = {
                    baseAncillaries: {
                        itinerary: [],
                        leg: [],
                        traveler: [],
                        hasAncillaries: false
                    },
                    bagaggeAncillaries: {
                        itinerary: [],
                        leg: [],
                        traveler: [],
                        hasAncillaries: false
                    }
                };

                if (responseFareDetail.itineraryAncillaries) {
                    for (let i = 0; i < responseFareDetail.itineraryAncillaries.length; i++) {
                        let anc = responseFareDetail.itineraryAncillaries[i];
                        if (ancillaryTypeBagagge.includes(anc.category.toUpperCase())) {
                            mapping.bagaggeAncillaries.itinerary.push(anc);
                            bagaggeAncillariesHasAncillaries = true;
                        }
                        else {
                            mapping.baseAncillaries.itinerary.push(anc);
                            baseAncillariesHasAncillaries = true;
                        }
                    }
                }

                if (responseFareDetail.legAncillaries) {

                    for (let i = 0; i < responseFareDetail.legAncillaries.length; i++) {
                        let anc = responseFareDetail.legAncillaries[i];
                        if (ancillaryTypeBagagge.includes(anc.category.toUpperCase())) {
                            mapping.bagaggeAncillaries.leg.push(anc);
                            bagaggeAncillariesHasAncillaries = true;
                        }
                        else {
                            mapping.baseAncillaries.leg.push(anc);
                            baseAncillariesHasAncillaries = true;
                        }
                    }
                }

                if (responseFareDetail.travelerAncillaries) {

                    for (let i = 0; i < responseFareDetail.travelerAncillaries.length; i++) {
                        let traveler = responseFareDetail.travelerAncillaries[i];

                        let trvBaggage = traveler.ancillaries.filter(anc => ancillaryTypeBagagge.includes(anc.category.toUpperCase()));
                        let trvBase = traveler.ancillaries.filter(anc => !ancillaryTypeBagagge.includes(anc.category.toUpperCase()));

                        if (trvBaggage && trvBaggage.length > 0)
                            bagaggeAncillariesHasAncillaries = true;

                        if (trvBase && trvBase.length > 0)
                            baseAncillariesHasAncillaries = true;

                        mapping.bagaggeAncillaries.traveler.push({ index: traveler.index, ancillaries: trvBaggage });
                        mapping.baseAncillaries.traveler.push({ index: traveler.index, ancillaries: trvBase });
                    }

                }

                mapping.bagaggeAncillaries.hasAncillaries = bagaggeAncillariesHasAncillaries;
                mapping.baseAncillaries.hasAncillaries = baseAncillariesHasAncillaries;

                setFlightAncillaries(mapping);
            }

        } else {
            setIsFareError(true);
        };
    }

    async function onClickBook(bookItem) {
        let normalizeAncillariesId = [];

        if (ancillariesSelectedBagagge) {

            for (let i = 0; i < ancillariesSelectedBagagge.length; i++) {
                let anc = ancillariesSelectedBagagge[i];
                let mapObj = {
                    id: anc.ancillary.id,
                    index: anc.paxIndex,
                    validValuesIds: anc.valueSelected.map(v => v.key)
                };

                normalizeAncillariesId.push(mapObj);
            }

        }

        if (ancillariesSelectedBase) {
            for (let i = 0; i < ancillariesSelectedBase.length; i++) {
                let anc = ancillariesSelectedBase[i];
                let mapObj = {
                    id: anc.ancillary.id,
                    index: anc.paxIndex,
                    validValuesIds: anc.valueSelected.map(v => v.key)
                };

                normalizeAncillariesId.push(mapObj);
            }
        }

        if (!bookItem)
            bookItem = {};

        bookItem.cultureCode = cultureName;
        bookItem.carpetId = avlCarpetId;
        bookItem.selectedItineraryUniqueId = parseInt(itineraryId);
        bookItem.selectedFareUniqueId = fareSelected;
        bookItem.selectedAncillariesId = normalizeAncillariesId;

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bookItem)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}flight/addtocart`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response.success) {
                if (quotationInfo && quotationInfo.quotationId) {
                    if (quotationInfo.isFromCart)
                        window.location.href = `cart-quotation`;
                    else
                        window.location.href = `quotationTO/${quotationInfo.quotationId}`;
                } else {
                    window.location.href = `cart`;
                }
            } else {
                setEnableCanAddToCart({ allowed: false, label: response.errorMsg });
            }

        } else {
            console.log(fetchedRes);
        }
    }

    function backToList() {
        window.location.href = `/flight/flightlist/?cId=${carpetId}&cfId=${clientFlowId}`;
    }

    return (
        <>
            <main className="bg-gray-300">
                {
                    configData.Settings.Website.EngineType === 'WITHNAVBAR' && <ProductNavBar moduleSelected="FLIGHT" />
                }
                {
                    configData.Settings.Website.ShowProductHeaderDetail && <div>
                        <ProductHeader text="" pageCode='Home_Gallery' />
                    </div>
                }
                <div className="container mt-5">

                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="cursor-pointer small mb-1" onClick={_ => backToList()}>
                                <span class="material-icons-outlined icon-small">arrow_back_ios</span> {t('ActionMenu:ReturnList')}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        {isLoading && <div className="col-12"><Loading textMsg={t('Product:Flight:LoadingDetail')} /></div>}
                        {!isLoading && isError && <div className="col-12  text-center text-danger py-2"><Error textMsg={t('Product:Flight:Error')} /></div>}
                        {!isLoading && !isError && flightFare &&
                            <>

                                {
                                    isFareError && !flightFareDetail && <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div className="bg-white text-danger rounded shadow mb-2">
                                            <div className=" row mb-3 mx-1">
                                                <div className="col-12 col-sm-12 p-1">
                                                    {t("Product:Flight:FareExpired")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9">
                                    <div className="no-gutters d-block d-sm-none">
                                        <FlightDetailResume
                                            fares={flightFareDetail && flightFareDetail.fares}
                                            fareLegInfo={flightFare}
                                            ancillariesSelectedBagagge={ancillariesSelectedBagagge}
                                            ancillariesSelectedBase={ancillariesSelectedBase}
                                            isLoadingFares={isLoadingFares}
                                            showCost={showCost}
                                            enableShowNetPrices={enableShowNetPrices}
                                            enableCanAddToCart={enableCanAddToCart}
                                            quotationInfo={quotationInfo}
                                            onBook={onClickBook} />
                                    </div>
                                    <FlightItemList
                                        item={flightFare}
                                        fareId={fareId}
                                        isViewDetail={true}
                                        showPriceBar={false}
                                    />
                                    {/* scegli tariffa */}
                                    <div className="card-body bg-white pt-2 px-0  pb-0 p-2 rounded mb-2 pr-4 d-none d-sm-block">
                                        <div className="w-100">
                                            <div className="h6 ">
                                                <div className="h6 mb-1">
                                                    <data m3ico="FlightTicket icon-15 mr-2">
                                                        <M3Icon iconName="FlightTicket" externalClass="icon-15 mr-2" />
                                                    </data>
                                                    <data m3lab="Product.Flight.ChooseFare">
                                                        {t(`Product:Flight:ChooseFare`)}
                                                    </data>
                                                </div>
                                                <label>
                                                    <data m3lab="Product.Flight.ChooseFareSubTitle">
                                                        {t(`Product:Flight:ChooseFareSubTitle`)}
                                                    </data>
                                                </label>
                                            </div>
                                            <div className="row mb-0">
                                                {flightFareDetail && flightFare.fares && flightFare.fares.length > 0 && flightFare.fares.map((fare, key) => {
                                                    return <FlightChooseFare
                                                        key={key}
                                                        fare={fare.uniqueId === fareSelected ? (flightFareDetail && flightFareDetail.fares && flightFareDetail.fares[0]) : fare}
                                                        fareSelected={fareSelected}
                                                        selectedFarePrice={flightFareDetailSelectedPrice}
                                                        handleClickFare={onClickFare} />
                                                })
                                                }
                                            </div>
                                            <div className="row mb-0">
                                                <div className="col-12">
                                                    {
                                                        isFareError && !flightFareDetail && <><h6 className="text-danger">{t("Product:Flight:FareExpired")}</h6></>
                                                    }
                                                    {
                                                        !isFareError && isLoadingFares && <Loading textMsg={t('Product:Flight:LoadingFares')} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* scegli tariffa mobile */}
                                    <div className="card-body bg-white pt-2 px-0  pb-0 p-2 rounded mb-2 d-block d-sm-none">
                                        <div className="w-100">
                                            <div className="h6 ">
                                                <div className="h6 mb-1">
                                                    <data m3ico="FlightTicket icon-15 mr-2">
                                                        <M3Icon iconName="FlightTicket" externalClass="icon-15 mr-2" />
                                                    </data>
                                                    <data m3lab="Product.Flight.ChooseFare">
                                                        {t(`Product:Flight:ChooseFare`)}
                                                    </data>
                                                </div>
                                                <label>
                                                    <data m3lab="Product.Flight.ChooseFareSubTitle">
                                                        {t(`Product:Flight:ChooseFareSubTitle`)}
                                                    </data>
                                                </label>
                                            </div>
                                            <div className="row mb-0">
                                                {flightFareDetail && flightFare.fares && flightFare.fares.length > 0 && flightFare.fares.map((fare, key) => {
                                                    return <FlightChooseFare
                                                        key={key}
                                                        fare={fare.uniqueId === fareSelected ? (flightFareDetail && flightFareDetail.fares && flightFareDetail.fares[0]) : fare}
                                                        fareSelected={fareSelected}
                                                        selectedFarePrice={flightFareDetailSelectedPrice}
                                                        handleClickFare={onClickFare} />
                                                })
                                                }
                                            </div>
                                            <div className="row mb-0">
                                                <div className="col-12">
                                                    {
                                                        isFareError && !flightFareDetail && <><h6 className="text-danger">{t("Product:Flight:FareExpired")}</h6></>
                                                    }
                                                    {
                                                        !isFareError && isLoadingFares && <Loading textMsg="Stiamo cercando le migliori tariffe" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* bagagli */}
                                    {
                                        flightAncillaries && flightAncillaries.bagaggeAncillaries && flightAncillaries.bagaggeAncillaries.hasAncillaries && <FlightAncillaryType
                                            ancillaries={flightAncillaries.bagaggeAncillaries}
                                            ancillariesTotalPrice={ancillariesSelectedBagaggeTotalPrice}
                                            type="BAGGAGE"
                                            flightFare={flightFareDetail ? flightFareDetail : flightFare}
                                            onChangeAncillary={onChangeAncillary} />
                                    }

                                    {/* servizi */}
                                    {
                                        flightAncillaries && flightAncillaries.baseAncillaries && flightAncillaries.baseAncillaries.hasAncillaries && <FlightAncillaryType
                                            ancillaries={flightAncillaries.baseAncillaries}
                                            ancillariesTotalPrice={ancillariesSelectedBaseTotalPrice}
                                            type="BASE"
                                            flightFare={flightFareDetail ? flightFareDetail : flightFare}
                                            onChangeAncillary={onChangeAncillary} />
                                    }

                                    {/* condizioni */}
                                    {
                                        flightFareRules && flightFareRules.length > 0 && flightFareRules.some(fareRule => fareRule && fareRule.fareRules && fareRule.fareRules.length > 0) && <div className="card border-light p-1 mt-2 shadow">
                                            <div className="w-100 mt-1">
                                                <div className=" text-center float-start w-90">
                                                    <label>
                                                        <data m3lab="Product.Flight.ConditionsSubTitle">
                                                            {t(`Product:Flight:ConditionsSubTitle`)}
                                                        </data>
                                                    </label>
                                                </div>
                                                <div className="h6 float-end">
                                                    <data m3lab="General.Info">
                                                        {t(`General:Info`)}:
                                                    </data>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                    <div className="text-center">
                                                        <h1 className="">
                                                            <data m3ico="ContentPaste mr-2 h5r">
                                                                <M3Icon iconName="ContentPaste" externalClass="mr-2 h5r" />
                                                            </data>
                                                        </h1>
                                                    </div>
                                                    <div className="h5">
                                                        <data m3lab="General.Conditions">
                                                            {t(`General:Conditions`)}
                                                        </data>
                                                    </div>
                                                </div>
                                                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                                    <div data-bs-toggle="collapse" href="#Conditions" role="button" aria-expanded="false" aria-controls="Conditions" className="collapsed">
                                                        {t(`Product:Flight:ConditionSubTitle`)}
                                                        <div className="text-center w-100">
                                                            <data m3ico="ArrowDown">
                                                                <M3Icon iconName="ArrowDown" externalClass="" />
                                                            </data>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-100 collapse" id="Conditions">
                                                    <ul className="list-group list-group-flush" id="Conditions">
                                                        <FlightFareRules fareRules={flightFareRules} />
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {/* Prezzo */}
                                    <div className="card border-light p-1 mt-2 shadow">
                                        <div className="w-100 mt-1">
                                            <div className=" text-center float-start w-90">
                                                <label>
                                                    <data m3lab="Product.Flight.RegoleTariffaSubTitle">
                                                        {t(`Product:Flight:RegoleTariffaSubTitle`)}
                                                    </data>
                                                </label>
                                            </div>
                                            <div className="h6 float-end">
                                                <data m3lab="General.Info">
                                                    {t(`General:Info`)}:
                                                </data>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                <div className="text-center">
                                                    <h1 className="">
                                                        <data m3ico="TableRows mr-2 h5r">
                                                            <M3Icon iconName="TableRows" externalClass="mr-2 h5r" />
                                                        </data>
                                                    </h1>
                                                </div>
                                                <div className="h5 text-center">
                                                    <data m3lab="Product:Flight:Price`">
                                                        {t(`Product:Flight:Price`)}
                                                    </data>
                                                </div>
                                            </div>
                                            <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                                <div data-bs-toggle="collapse" href="#Price" role="button" aria-expanded="true" aria-controls="Price" className="">

                                                    {t('Product:Flight:FaresDetailSubtitle')}
                                                    <div className="text-center w-100">
                                                        <data m3ico="ArrowDown">
                                                            <M3Icon iconName="ArrowDown" externalClass="" />
                                                        </data>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-100 collapse" id="Price">
                                                <div className="collapse" id="Price">
                                                    {
                                                        flightFare.legs && flightFare.legs.length > 0 && flightFare.legs.map((leg, key) => {
                                                            return <FlightPriceFareDetail
                                                                key={key}
                                                                fares={flightFareDetail ? flightFareDetail.fares : flightFare.fares}
                                                                leg={leg}
                                                                legKey={key}
                                                            />
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-12 col-lg-3 no-gutters">
                                    <FlightDetailResume
                                        fares={flightFareDetail && flightFareDetail.fares}
                                        fareLegInfo={flightFare}
                                        ancillariesSelectedBagagge={ancillariesSelectedBagagge}
                                        ancillariesSelectedBase={ancillariesSelectedBase}
                                        isLoadingFares={isLoadingFares}
                                        showCost={showCost}
                                        enableShowNetPrices={enableShowNetPrices}
                                        enableCanAddToCart={enableCanAddToCart}
                                        quotationInfo={quotationInfo}
                                        onBook={onClickBook} />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </main>
        </>
    );
}