import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { useAuth } from '../../Auth/AuthProvider';
import { getCurrentUserFromJwtToken, getIconFavoutire, getWebsiteId } from '../../../js/Utils.js';
import { Loading } from '../../Common/Loading';
import { Error } from '../../Common/Error';
import { createCruiseCarpetAvailability } from '../../../js/ProductService';
import { ProductNavBar } from '../SearchEngine/ProductNavBar';
import { Gallery } from '../../Product/Gallery';
import { CruiseDetailStaticData } from './components/CruiseDetailStaticData';
import { CruiseInfoLink } from './components/CruiseInfoLink';
import { CruiseInfoHighlights } from './components/CruiseInfoHighlights';
import { CruiseDetailGroupCabinCategory } from './components/CruiseDetailGroupCabinCategory';
import { CruiseDetailGroupCabinFilter } from './components/CruiseDetailGroupCabinFilter';
import { ListSearchEngineCV } from '../SearchEngine/compactView/ListSearchEngineCV';
import { ListSearchEngineCVMobile } from "../SearchEngine/compactView/ListSearchEngineCVMobile"

export const CruiseDetail = () => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    let params = new URLSearchParams(window.location.search);
    let carpetId = params.get('cId');
    let clientFlowId = params.get('cfId');
    let cruiseCode = params.get('cruiseCode');
    let cabinCategoryCode = params.get('ccCode');

    let { getQuotationInfoSession } = useAuth();

    const [cruiseData, setCruiseData] = useState(null);
    const [cruiseDetail, setCruiseDetail] = useState(null);
    const [avlCarpetId, setAvlCarpetId] = useState(null);
    const [promotionsFilter, setPromotionsFilter] = useState(null);
    const [discountPaxesCodes, setDiscountPaxesCodes] = useState(null);
    const [anaDiscountPaxesCodes, setAnaDiscountPaxesCodes] = useState(null);
    const [selectDiscountPaxesCodes, setSelectDiscountPaxesCodes] = useState([]);
    const [selectFilterPromo, setSelectFilterPromo] = useState([]);
    const [resetFilter, setResetFilter] = useState(1);
    const [cruiseSearchParameters, setCruiseSearchParameters] = useState(null);

    const [showPriceBar, setShowPriceBar] = useState(false);

    // page
    const [isLoading, setIsLoading] = useState(true);
    const [isErrorStaticData, setIsErrorStaticData] = useState(false);
    const [isStaticView, setIsStaticView] = useState(false);
    const [isCabinCategoryError, setIsCabinCategoryError] = useState(false);
    const [isLoadingCabinCategory, setIsLoadingCabinCategory] = useState(false);
    const [quotationInfo, setQuotationInfo] = useState(null);

    // roles
    const [showCost, setShowCost] = useState(false);
    const [enableShowNetPrices, setEnableShowNetPrices] = useState(false);
    const [enableCanAddToCart, setEnableCanAddToCart] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        setIsErrorStaticData(false);

        getCreateCarpet(cruiseCode);

        try {

            let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
            let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);

            if (currentUser.roles) {
                if (!currentUser.roles.includes("FE_NonVisualizzaNetto"))
                    setEnableShowNetPrices(true);

                if (currentUser.roles.includes("FE_ShowCost"))
                    setShowCost(true);
            }

        } catch (ex) {
        }
    }, []);

    useEffect(() => {

        if (cruiseData) {
            getCruiseDiscountPax();
            getCabinCategory([]);
        }


    }, [cruiseData])

    useEffect(() => {

        if (avlCarpetId)
            getCruiseData(cruiseCode);


    }, [avlCarpetId])

    useEffect(() => {
        if (getQuotationInfoSession && getQuotationInfoSession.quotationId)
            setQuotationInfo(getQuotationInfoSession);
        else
            setQuotationInfo(null);

    }, [getQuotationInfoSession]);

    // API carpet
    async function getCreateCarpet(cruiseCodeParam) {
        var inputData = {
            "carpetOperation": "AVL",
            "clientFlowId": clientFlowId,
            "sessionToken": cookies.get("SessionToken"),
            "websiteId": getWebsiteId(),
            "cruiseSearchParameters": null,
            "productSubType": 31,
            "cultureCode": cultureName,
            "srcCarpetId": carpetId,
            "cruiseCode": cruiseCodeParam ? cruiseCodeParam : "",
        };
        const response = await createCruiseCarpetAvailability(inputData);

        if (response.success && response.status !== "Error") {
            setAvlCarpetId(response.carpetId);
        } else {
            setIsCabinCategoryError(true);
        }
    }

    async function getCruiseDiscountPax() {
        let inputData = {
            "cultureCode": cultureName,
            "carpetId": avlCarpetId,
            "clientFlowId": clientFlowId,
            "cabinCategoryCode": cabinCategoryCode,
            "cruiseCode": cruiseCode,
            "discountPaxesCodes": selectDiscountPaxesCodes,
            "anaDiscountPaxesCodes": anaDiscountPaxesCodes
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}cruise/GetCruiseDiscountPax`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response.success) {
                setDiscountPaxesCodes(response.discountPaxesCodes);
                setAnaDiscountPaxesCodes(response.anaDiscountPaxesCodes);
            }
        }
    }

    async function getCruiseData(cruiseCodeParam) {
        let inputData = {
            "cultureCode": cultureName,
            "carpetId": avlCarpetId,
            "clientFlowId": clientFlowId,
            "cabinCategoryCode": cabinCategoryCode,
            "cruiseCode": cruiseCodeParam,
            "discountPaxesCodes": selectDiscountPaxesCodes
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}cruise/GetCruiseDetail`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response.success) {
                if (!response.cruise)
                    setIsErrorStaticData(true);
                else
                    setCruiseData(response.cruise);

                setCruiseSearchParameters(response.cruiseSearchParameters);

                if (response.permissions)
                    setEnableCanAddToCart(response.permissions['ADDTOCART']);

            } else {
                setIsErrorStaticData(true);
                console.error(response.errorMsg);
            }
        } else {
            setIsErrorStaticData(true);
        }

        setIsLoading(false);
    }

    async function getCabinCategory(selectDiscountPaxesCodesParam) {
        setIsLoadingCabinCategory(true);
        setCruiseDetail(null);
        setPromotionsFilter(null);
        setIsCabinCategoryError(false);
        setResetFilter(resetFilter + 1);

        let inputData = {
            "cultureCode": cultureName,
            "carpetId": avlCarpetId,
            "clientFlowId": clientFlowId,
            "cabinCategoryCode": cabinCategoryCode,
            "cruiseCode": cruiseCode,
            "discountPaxesCodes": selectDiscountPaxesCodesParam
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}cruise/GetCruiseCabinCategories`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response.success) {
                if (!response.cruise)
                    setIsCabinCategoryError(true);
                else
                    setCruiseDetail(response.cruise);

                setPromotionsFilter(response.promotionsFilter);
            } else {
                setIsCabinCategoryError(true);
                console.error(response.errorMsg);
            }
        } else {
            setIsCabinCategoryError(true);
        }

        setIsLoadingCabinCategory(false);
    }

    const onChangeShowPriceBar = () => {
        setShowPriceBar(!showPriceBar);
    }

    function onClickFavourite() {
        //let favourite = !cruiseData.isFavourite;
        //setIsFavourite(favourite);

        //let inputData = {
        //    productType: configData.Settings.Products.Cruise.IdTipoProdotto,
        //    productId: structure ? structure : structureId,
        //    operation: favourite ? "A" : "R",
        //    carpetId: carpetId
        //}

        //saveFavourite(inputData);
    }

    function onSelectDiscounts(codes) {
        if (codes && codes.length > 0)
            setSelectDiscountPaxesCodes(codes);
        else
            setSelectDiscountPaxesCodes([]);

        getCabinCategory(codes);
    }

    function onSelectPromoFilter(promos) {
        setSelectFilterPromo(promos);
    }

    async function onClickBook(bookItem) {
        bookItem.cultureCode = cultureName;
        bookItem.carpetId = avlCarpetId;
        bookItem.clientFlowId = clientFlowId;
        bookItem.cruiseCode = cruiseCode;
        bookItem.discountPaxesCodes = selectDiscountPaxesCodes;

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bookItem)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}cruise/addtocart`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response.success) {
                if (quotationInfo && quotationInfo.quotationId) {
                    if (quotationInfo.isFromCart)
                        window.location.href = `cart-quotation`;
                    else
                        window.location.href = `quotationTO/${quotationInfo.quotationId}`;
                } else {
                    window.location.href = `cart`;
                }
            }
        } else {
            console.log(fetchedRes);
        }
    }

    function backToList() {
        window.location.href = `/cruise/cruiselist/?cId=${carpetId}&cfId=${clientFlowId}`;
    }

    return (
        <>
            <main className="bg-gray-300">
                {
                    configData.Settings.Website.EngineType === 'WITHNAVBAR' && <ProductNavBar moduleSelected="CRUISE" />
                }
                <div className="container mt-5">

                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="cursor-pointer small mb-1" onClick={_ => backToList()}>
                                <span class="material-icons-outlined icon-small">arrow_back_ios</span> {t('ActionMenu:ReturnList')}
                            </div>
                        </div>
                    </div>
                    <div className="card border-light bg-white p-1">
                        {isLoading && !isErrorStaticData && <Loading textMsg={t('MyBook:Loading')} />}
                        {
                            !isLoading && isErrorStaticData && <div className="card border-light bg-gray-100 small mb-2">
                                <div className="row small ">
                                    <div className="col-12 ">
                                        <Error textMsg={t('Product:Cruise:ErrorDetail')} />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            !isLoading && cruiseData && <>
                                <div className="row px-2">
                                    <div className="col-9">
                                        <label><var>{cruiseData.city}</var></label>
                                    </div>
                                    <div className="col-3 text-right d-none">
                                        {getIconFavoutire(cruiseData.isFavourite, t, onClickFavourite, true)}
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <Gallery gallery={cruiseData.images ? cruiseData.images.map((img) => { return img.path }) : null} modeView={isStaticView ? "modal" : ""} onlyOne={true} productType={configData.Settings.Products.Cruise.IdTipoProdotto } />
                                </div>
                                <CruiseDetailStaticData cruiseData={cruiseData} />
                                <div className="row">
                                    <div className="col-8">
                                        <CruiseDetailGroupCabinFilter
                                            promotionsFilter={promotionsFilter}
                                            discountPaxesCodes={discountPaxesCodes}
                                            onSelectDiscounts={onSelectDiscounts}
                                            onSelectPromoFilter={onSelectPromoFilter}
                                            resetFilter={resetFilter}
                                            avlCarpetId={avlCarpetId}
                                        />

                                        {cruiseDetail && cruiseDetail.memberCard &&
                                            <div className="card border-light pt-2 mt-4">
                                                <div className="row">
                                                    <div className="col-12">
                                                        {t(`Cruise:MemberCard`)}: {cruiseDetail.memberCard}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            isLoadingCabinCategory && <div className="card border-light pt-2 mt-4">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <Loading textMsg={t('Product:Cruise:CabinLoading')} />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            !isLoadingCabinCategory && isCabinCategoryError && <div className="card border-light pt-2 mt-4">
                                                <div className="row">
                                                    <div className="col-12 text-center text-danger py-2">
                                                        <Error textMsg={t('Product:Cruise:CabinNotFound')} />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            cruiseDetail && <CruiseDetailGroupCabinCategory
                                                cruiseDetail={cruiseDetail}
                                                cruiseSearchParameters={cruiseSearchParameters}
                                                selectFilterPromo={selectFilterPromo}
                                                onChangeShowPriceBar={onChangeShowPriceBar}
                                                enableCanAddToCart={enableCanAddToCart}
                                                quotationInfo={quotationInfo}
                                                showPriceBar={showPriceBar}
                                                showCost={showCost}
                                                onClickBook={onClickBook}
                                                enableShowNetPrices={enableShowNetPrices}
                                                resetFilter={resetFilter} />
                                        }

                                    </div>
                                    <div className="col-4">
                                        <div className="w-100 my-4 px-2">
                                            {
                                                cruiseData && <>
                                                    <ListSearchEngineCV
                                                        imageUrl={cruiseData.thumbUrl}
                                                        searchParameters={cruiseSearchParameters}
                                                        productType={configData.Settings.Products.Cruise.IdTipoProdotto} />

                                                    <ListSearchEngineCVMobile
                                                        imageUrl={cruiseData.thumbUrl}
                                                        searchParameters={cruiseSearchParameters}
                                                        productType={configData.Settings.Products.Cruise.IdTipoProdotto} />
                                                </>
                                            }
                                        </div>
                                        <div className="w-100 mt-2">
                                            <CruiseInfoHighlights highlights={cruiseData.highlights} />
                                        </div>
                                        <div className="w-100  mt-4">
                                            <CruiseInfoLink infoProviderLinks={cruiseData.infoProviderLinks} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </main>
        </>
    );
}