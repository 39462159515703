import React, { useEffect, useState, useRef } from 'react';
import configData from "../appsettings.json";
import { useAuth } from './Auth/AuthProvider';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { GetModulesRules, getAuthToken, getCurrentUserFromJwtToken, isMobile } from "../js/Utils";
import { StructureSearchEngine } from './Product/SearchEngine/StructureSearchEngine';
import { ActivitySearchEngine } from './Product/SearchEngine/ActivitySearchEngine';
import { TourSearchEngine } from './Product/SearchEngine/TourSearchEngine';
import { FlightSearchEngine } from './Product/SearchEngine/FlightSearchEngine';
import { TrainSearchEngine } from './Product/SearchEngine/TrainSearchEngine';
import { TrippieSearchEngine } from './Product/SearchEngine/TrippieSearchEngine';
import { CarSearchEngine } from './Product/SearchEngine/CarSearchEngine';
import { TransferSearchEngine } from './Product/SearchEngine/TransferSearchEngine';
import { CruiseSearchEngine } from './Product/SearchEngine/CruiseSearchEngine';
import { WebContent } from '../components/WebContent/WebContent';
import { M3Icon } from "./Common/M3Icon"
import { ProductHeader } from './Product/Common/ProductHeader';
import { ProductNavBar } from './Product/SearchEngine/ProductNavBar';

export const Engine = ({ isFake, startModule, haveCustomWebContent }) => {
    const { t } = useTranslation();
    const [rules, setRules] = useState(null);
    const [moduleSelected, setModuleSelected] = useState('');
    const [pageWebContent, setPageWebContent] = useState('');

    const [quotationSearchParameters, setQuotationSearchParameters] = useState({
        activitySearchParameters: null,
        carSearchParameters: null,
        flightSearchParameters: null,
        hotelSearchParameters: null,
        tourSearchParameters: null,
        transferSearchParameters: null
    });

    let { getModuleSelected, onChangeModuleSelected, getQuotationInfoSession } = useAuth();

    let { module } = useParams();

    const engineContainerRef = useRef();
    const [bannerStyle, setBannerStyle] = useState({ marginTop: '0px' });

    useEffect(() => {
        const callGetModuleRules = async () => {
            const moduleRules = await GetModulesRules();
            setRules(moduleRules);
        };

        const resizeObserver = new ResizeObserver(() => {
            // Do what you want to do when the size of the element changes
            try {
                let engineHeight = engineContainerRef.current.clientHeight;
                let marginTop = engineHeight - 100;

                if (marginTop > 0 && !isMobile()) {
                    setBannerStyle({
                        ...bannerStyle,
                        marginTop: marginTop + 'px'
                    });
                } else {
                    setBannerStyle({
                        ...bannerStyle,
                        marginTop: '0px'
                    });
                }

            } catch (ex) {
                console.error(ex);
            }
        });
        resizeObserver.observe(engineContainerRef.current);

        callGetModuleRules();

        if (startModule) {
            onChangeModuleSelected(startModule);
            setModuleSelected(startModule);
            setPageProduct(startModule);
        }

    }, [])

    useEffect(() => {

        if (getQuotationInfoSession && getQuotationInfoSession.quotationInfoSearchParam) {
            setQuotationSearchParameters(getQuotationInfoSession.quotationInfoSearchParam);
        }

    }, [getQuotationInfoSession])

    useEffect(() => {
        let nextModule = getModuleSelected;
        if (!nextModule || nextModule === 'NOMODULE')
            nextModule = startModule;

        setModuleSelected(nextModule);
        setPageProduct(nextModule);
    }, [getModuleSelected])

    function setPageProduct(productName) {
        try {

            switch (productName) {
                case 'ACTIVITY':
                    {
                        if (haveCustomWebContent)
                            setPageWebContent('ActivityHome');
                        break;
                    }
                case 'CARRENTAL':
                case 'RENTCAR':
                    {
                        if (haveCustomWebContent)
                            setPageWebContent('CarHome');
                        break;
                    }
                case 'CRUISE':
                    {
                        if (haveCustomWebContent)
                            setPageWebContent('CruiseHome');
                        break;
                    }
                case 'STRUCTURE':
                    {
                        if (haveCustomWebContent)
                            setPageWebContent('HotelHome');
                        break;
                    }
                case 'TRANSFER':
                    {
                        if (haveCustomWebContent)
                            setPageWebContent('TransferHome');
                        break;
                    }
                case 'TOUR':
                    {
                        if (haveCustomWebContent)
                            setPageWebContent('TourHome');
                        break;
                    }
                case 'FLIGHT':
                    {
                        if (haveCustomWebContent)
                            setPageWebContent('FlightsHome');
                        break;
                    }
                case 'TRAIN':
                    {
                        if (haveCustomWebContent)
                            setPageWebContent('TrainHome');
                        break;
                    }
                case 'CRUISE':
                    {
                        if (haveCustomWebContent)
                            setPageWebContent('CruiseHome');
                        break;
                    }
                case 'TRIPPIE':
                    {
                        if (haveCustomWebContent)
                            setPageWebContent('TrippieHome');
                        break;
                    }
                default: {
                    return true;
                }
            }


        } catch (ex) {
            setPageWebContent('');
        }
    }


    function isVisibleProduct(productName) {
        if (!rules)
            return false;

        let isVisibleTrippie = false;
        try {
            let token = getAuthToken();
            let currentUser = getCurrentUserFromJwtToken(token);
            if (currentUser && currentUser.roles.includes("W_EngineTrippie"))
                isVisibleTrippie = true;
        } catch (ex) { }

        try {

            switch (productName) {
                case 'ACTIVITY':
                    {
                        return rules["ACTIVITY"];
                        break;
                    }
                case 'CARRENTAL':
                case 'RENTCAR':
                    {
                        return rules["CARRENTAL"];
                        break;
                    }
                case 'CRUISE':
                    {
                        return rules["CRUISE"];
                        break;
                    }
                case 'STRUCTURE':
                    {
                        return rules["STRUCTURE"];
                        break;
                    }
                case 'TRANSFER':
                    {
                        return rules["TRANSFER"];
                        break;
                    }
                case 'TOUR':
                    {
                        return rules["TOUR"];
                        break;
                    }
                case 'FLIGHT':
                    {
                        return rules["FLIGHT"];
                        break;
                    }
                case 'TRAIN':
                    {
                        return rules["TRAIN"];
                        break;
                    }
                case 'CRUISE':
                    {
                        return rules["CRUISE"];
                        break;
                    }
                case 'TRIPPIE':
                    {
                        return isVisibleTrippie;
                        break;
                    }
                default: {
                    return true;
                }
            }


        } catch (ex) {
            return false;
        }
    }

    function handleClick(url, withBlank = false) {
        if (withBlank)
            window.open(url, '_blank');
        else
            window.location.href = url;
    }

    function openLoginModal(event) {

        let isClickDisabled = false;
        try {
            isClickDisabled = event.target.id.includes('btnClose');
        } catch (ex) { }

        if (isFake && !isClickDisabled) {
            const button = document.querySelector('.open-login-modal');
            button.click();
        }
    }

    return (
        <main className="bg-white">
            <div className='engine-container position-relative bg-custom2'>
                <div className='w-100'>
                    <ProductHeader pageCode='Home_Gallery' height="400px" />
                </div>
                <div id="engine-container"
                    ref={engineContainerRef}
                    className="container-fluid"
                    onClick={event => openLoginModal(event)}>
                    <div id="engine-child" className=" text-left pl-4 pr-4">
                        {
                            configData.Settings.Website.EngineType === 'WITHNAVBAR' && <ProductNavBar moduleSelected={moduleSelected} setModuleSelected={setModuleSelected} />
                        }
                        <div className="bg-custom  rounded-top">

                            <div className="pr-4 pl-2 search-engine-mobile">
                                {
                                    (module === 'HOTEL' || (startModule === 'HOTEL' && isFake) || (isVisibleProduct('STRUCTURE') && moduleSelected === 'HOTEL')) && <>
                                        {
                                            configData.Settings.Website.EngineType === 'BASE' && <h4 className="text-white">{t("Product:Structure:Item")}</h4>
                                        }
                                        <StructureSearchEngine
                                            typeSearch="SRC"
                                            modeView="SEARCHENGINE"
                                            isFake={isFake}
                                            searchParameters={quotationSearchParameters.hotelSearchParameters} />
                                    </>
                                }
                                {
                                    (module === 'ACTIVITY' || (startModule === 'ACTIVITY' && isFake) || (isVisibleProduct('ACTIVITY') && moduleSelected === 'ACTIVITY')) && <>
                                        {
                                            configData.Settings.Website.EngineType === 'BASE' && <h4 className="text-white">{t("Product:Activity:Item")}</h4>
                                        }
                                        <ActivitySearchEngine
                                            typeSearch="SRC"
                                            modeView="SEARCHENGINE"
                                            isFake={isFake}
                                            searchParameters={quotationSearchParameters.activitySearchParameters} />
                                    </>
                                }
                                {
                                    (module === 'TOUR' || (startModule === 'TOUR' && isFake) || (isVisibleProduct('TOUR') && moduleSelected === 'TOUR')) && <>
                                        {
                                            configData.Settings.Website.EngineType === 'BASE' && <h4 className="text-white">{t("Product:Tour:Item")}</h4>
                                        }
                                        <TourSearchEngine
                                            typeSearch="SRC"
                                            modeView="SEARCHENGINE"
                                            isFake={isFake}
                                            searchParameters={quotationSearchParameters.tourSearchParameters} />
                                    </>
                                }

                                {
                                    (module === 'CARRENTAL' || (startModule === 'CARRENTAL' && isFake) || (isVisibleProduct('CARRENTAL') && moduleSelected === 'CARRENTAL')) &&
                                    <>
                                        {
                                            configData.Settings.Website.EngineType === 'BASE' && <h4 className="text-white">{t("Product:RentCar:Item")}</h4>
                                        }
                                        <CarSearchEngine
                                            typeSearch="SRC"
                                            modeView="SEARCHENGINE"
                                            isFake={isFake}
                                            searchParameters={quotationSearchParameters.carSearchParameters} />
                                    </>
                                }
                                {
                                    (module === 'TRANSFER' || (startModule === 'TRANSFER' && isFake) || (isVisibleProduct('TRANSFER') && moduleSelected === 'TRANSFER')) && <>
                                        {
                                            configData.Settings.Website.EngineType === 'BASE' && <h4 className="text-white">{t("Product:Transfer:Item")}</h4>
                                        }
                                        <TransferSearchEngine
                                            typeSearch="SRC"
                                            modeView="SEARCHENGINE"
                                            isFake={isFake}
                                            searchParameters={quotationSearchParameters.transferSearchParameters} />
                                    </>
                                }
                                {
                                    (module === 'FLIGHT' || (startModule === 'FLIGHT' && isFake) || (isVisibleProduct('FLIGHT') && moduleSelected === 'FLIGHT')) && <>
                                        {
                                            configData.Settings.Website.EngineType === 'BASE' && <h4 className="text-white">{t("Product:Flight:Item")}</h4>
                                        }
                                        <FlightSearchEngine
                                            typeSearch="SRC"
                                            modeView="SEARCHENGINE"
                                            isFake={isFake}
                                            searchParameters={quotationSearchParameters.flightSearchParameters} />
                                    </>
                                }
                                {
                                    (module === 'TRAIN' || (startModule === 'TRAIN' && isFake) || (isVisibleProduct('TRAIN') && moduleSelected === 'TRAIN')) && <>
                                        {
                                            configData.Settings.Website.EngineType === 'BASE' && <h4 className="text-white">{t("Product:Train:Item")}</h4>
                                        }
                                        <TrainSearchEngine
                                            typeSearch="SRC"
                                            modeView="SEARCHENGINE"
                                            isFake={isFake}
                                            searchParameters={quotationSearchParameters.trainSearchParameters} />
                                    </>
                                }
                                {
                                    (module === 'CRUISE' || (startModule === 'CRUISE' && isFake) || (isVisibleProduct('CRUISE') && moduleSelected === 'CRUISE')) && <>
                                        {
                                            configData.Settings.Website.EngineType === 'BASE' && <h4 className="text-white">{t("Product:Cruise:Item")}</h4>
                                        }
                                        <CruiseSearchEngine
                                            typeSearch="SRC"
                                            modeView="MODAL"
                                            isFake={isFake}
                                            searchParameters={quotationSearchParameters.cruiseSearchParameters} />
                                    </>
                                }
                                {
                                    (module === 'TRIPPIE' || (startModule === 'TRIPPIE' && isFake) || (isVisibleProduct('TRIPPIE') && moduleSelected === 'TRIPPIE')) && <>
                                        {
                                            configData.Settings.Website.EngineType === 'BASE' && <h4 className="text-white">{t("Trippie")}</h4>
                                        }
                                        <TrippieSearchEngine
                                            typeSearch="SRC"
                                            modeView="SEARCHENGINE"
                                            isFake={isFake} />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="engine_webcontent" className="container-fluid" style={bannerStyle}>
                <WebContent pageCodeParam={pageWebContent} />
            </div>

        </main >
    );
}
