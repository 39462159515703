import React from 'react';
import configData from "../../../../appsettings.json";
import { GalleryList } from "../../Common/Image/GalleryList";
import { ProductPriceBlock } from '../../Common/Pricing/ProductPriceBlock';

export const ActivityItemListBlock = ({ item, showCost, showPriceBar, onClickSearch }) => {
    function onClickBlockSearch() {
        onClickSearch(item.activityId);
    }

    return (
        <div className="col-12 col-sm-3 col-md-3 col-lg-3 p-1 cursor-pointer" onClick={_ => onClickBlockSearch()}>
            <div className="card animate-up-5 h-100 shadow">
                <GalleryList
                    productId={item.activityId}
                    defaultImage={item.thumbUrl}
                    gallery={item.images}
                    alternativeText={item.name}
                />
                <div className="card-body p-1 m-0">
                    <div className="p-1 card-text">
                        <span className="card-title small p-0 m-0">
                            <var>{item.name}</var>
                        </span>
                    </div>
                </div>
                <div className="card-footer p-0">
                    <ProductPriceBlock
                        productType={configData.Settings.Products.Activity.IdTipoProdotto}
                        pricing={item.pricing}
                        supplier={item.supplier}
                        showPriceBar={showPriceBar}
                        showCost={showCost}
                        otherParam={{ buttonEnabled: true }}
                        typePrice="XPax" />
                </div>
            </div>
        </div>
    );
}