import React, { useEffect, useState } from 'react';
import configData from "../../../../appsettings.json";
import { ProductPrice } from "../../Common/Pricing/ProductPrice";
import { TrainItemHeader } from "./TrainItemHeader";
import { TrainBestFareGroupByService } from "./TrainBestFareGroupByService";
import { TrainFarePivot } from './TrainFarePivot.js';


export const TrainItemList = ({ item, showPriceBar, showCost, templateInfo, modalOpenProductId, onClickSearch }) => {

    const [selectFares, setSelectFares] = useState([]);

    const [totalPriceFare, setTotalPriceFare] = useState(null);
    const [supplier, setSupplier] = useState(null);
    const [showTrainPivot, setShowTrainPivot] = useState(false);

    useEffect(() => {

        let updSelectFares = [];
        for (let i = 0; i < item.fares.tariffe.length; i++) {
            let fare = item.fares.tariffe[i];
            if (fare.farePivot) {

                try {
                    let selectedFare = null;

                    for (let j = 0; j < fare.farePivot.length; j++) {
                        let rows = fare.farePivot[j].rows;

                        for (let k = 0; k < rows.length; k++) {
                            let fc = rows[k];
                            if (fc && fc.fareComponents && fc.fareComponents.uniqueId === fare.bestUniqueId)
                                selectedFare = { fareComponents: fc.fareComponents, fareArgs: fare.fareArgs };
                        }

                        if (selectedFare)
                            break;
                    }

                    updSelectFares.push(selectedFare);

                } catch (ex) {
                    console.log(ex);
                }
            }


            if (fare.supplier)
                setSupplier(fare.supplier);
        }

        setShowTrainPivot(false);
        setSelectFares(updSelectFares);

    }, [item])

    useEffect(() => {

        let totalPricing = {
            valutaFornitore: "EUR",
            valutaCliente: "EUR",
            displayPriceCurrency: "EUR",
            costoFornitore: 0,
            networkPrice: 0,
            invoicePrice: 0,
            agencyPrice: 0,
            agencyPriceCommissionable: 0,
            agencyFee: 0,
            agencyMarkupPerc: 10,
            agencyMarkupVal: 0,
            commission: 0,
            displayPrice: 0,
            displayNet: 0,
            isCommisionable: false
        };

        if (selectFares)
            for (let i = 0; i < selectFares.length; i++) {
                let fare = selectFares[i].fareComponents;

                totalPricing.valutaFornitore = fare.pricing.valutaFornitore;
                totalPricing.valutaCliente = fare.pricing.valutaCliente;
                totalPricing.displayPriceCurrency = fare.pricing.displayPriceCurrency;

                totalPricing.costoFornitore += fare.pricing.costoFornitore;
                totalPricing.networkPrice += fare.pricing.networkPrice;
                totalPricing.invoicePrice += fare.pricing.invoicePrice;
                totalPricing.agencyPrice += fare.pricing.agencyPrice;
                totalPricing.agencyPriceCommissionable += fare.pricing.agencyPriceCommissionable;
                totalPricing.agencyFee += fare.pricing.agencyFee;
                totalPricing.agencyMarkupPerc = fare.pricing.agencyMarkupPerc;
                totalPricing.agencyMarkupVal += fare.pricing.agencyMarkupVal;
                totalPricing.commission += fare.pricing.commission;
                totalPricing.displayPrice += fare.pricing.displayPrice;
                totalPricing.displayNet += fare.pricing.displayNet;

                totalPricing.isCommisionable = fare.pricing.isCommisionable;
            }

        setTotalPriceFare(totalPricing);
    }, [selectFares])

    function onModalOpenProductId() {
        modalOpenProductId(item);
    }

    function onSelectFares(service, key, fareArgs) {
        let updSelectFares = [...selectFares];
        updSelectFares[key].fareComponents = service;
        updSelectFares[key].fareArgs = fareArgs;

        setShowTrainPivot(true);
        setSelectFares(updSelectFares);
    }

    function handleClickSearch() {
        onClickSearch(item.leg.uniqueId, selectFares);
    }

    return (
        <div className="card shadow shadow-hover mb-4 animate-up-3 p-1">
            {
                <div className="row no-gutters">
                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                        <div className="card-body pt-2 px-0  pb-0">
                            <div>
                                <div className="w-100">

                                    <TrainItemHeader
                                        item={item}
                                        supplier={supplier}
                                        onModalOpenProductId={onModalOpenProductId} />
                                    {
                                        item && item.fares && <TrainBestFareGroupByService
                                            fares={item.fares}
                                            showCost={showCost}
                                            templateInfo={templateInfo}
                                            selectFares={selectFares}
                                            onSelectFares={onSelectFares} />
                                    }
                                    {
                                        showTrainPivot && item && item.fares && <TrainFarePivot
                                            leg={item.leg}
                                            fares={item.fares}
                                            showCost={showCost}
                                            templateInfo={templateInfo}
                                            selectFares={selectFares}
                                            onSelectFares={onSelectFares} />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 p-1 pr-3">
                        {
                            totalPriceFare && <ProductPrice
                                itineraryId={item.uniqueId}
                                productType={configData.Settings.Products.Train.IdTipoProdotto}
                                pricing={totalPriceFare ? totalPriceFare : null}
                                supplier={supplier ? supplier : null}
                                showPriceBar={showPriceBar}
                                typePrice="TotPrice"
                                otherParam={{ buttonLabel: 'ActionMenu:Next', buttonClassName: 'tp-btn-manage', buttonEnabled: true }}
                                onClickSearch={handleClickSearch}
                            />
                        }
                    </div>
                </div>
            }
        </div>
    );
}